import firebase from '../firebase';

var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://daily-dinners.com/#/login',

    // url :'http://localhost:3000/#/login',
    handleCodeInApp: true,

    // iOS: {
    //   bundleId: 'com.example.ios'
    // },

    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },

    // dynamicLinkDomain: 'example.page.link'
};

const ResetPassword = (email) => {
    firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
        .then(function() {
          // Password reset email sent.
        })
        .catch(function(error) {
          // Error occurred. Inspect error.code.
        });
};

export default ResetPassword;