import firebase from '../firebase';

const PostNewsletterEmail = async (email) => {
    try {
        return await firebase.auth().signInAnonymously().then(async res => {
            let newsletterEmails = firebase.firestore().collection('newsletterEmails');

            await newsletterEmails.add({ email });
    
            return true;
        });
    } catch (error) {
        console.log('error posting newsletter email:', error);
        return false;
    }
};

export default PostNewsletterEmail;