import React, { useState } from 'react';
import CheckoutForm from '../components/CheckoutForm';
import PurchaseButton from '../components/PurchaseButton';
import SubscribeButton from '../components/SubscribeButton';
import './Purchase.css';

const Purchase = () => {
    const [showSubscriptionButton, setShowSubscriptionButton] = useState(false);
    const [showComboCheckoutForm, setShowComboCheckoutForm] = useState(false);
    const [showBookCheckoutForm, setShowBookCheckoutForm] = useState(false);
    const [showEbookCheckoutForm, setShowEbookCheckoutForm] = useState(false);

    const cover = require('../assets/images/Book.png');
    const ebookCover = require('../assets/images/Ebook.png');
    const comboCover = require('../assets/images/BookAndEbook.png');
    const sub = require('../assets/images/sub-icon.jpeg');

    let subscriptionForm = showSubscriptionButton ? 
    <SubscribeButton />
    :
    null;

    const eBookCheckoutForm = showEbookCheckoutForm ? 
    <CheckoutForm cost={21.60} item="ebook" />
    : 
    null;

    const bookButton = !showBookCheckoutForm ?
        <PurchaseButton action={() => {
            setShowBookCheckoutForm(!showBookCheckoutForm);
            setShowEbookCheckoutForm(false);
            setShowComboCheckoutForm(false);
            }} />
            :
        <div style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            flexDirection:'row',
        }}>
            <div className="checkout-area">
                <span className="checkout-text">Shipping is $4</span>
                <span className="checkout-text">Sales Tax is $2.80 (8.25%)</span>
                <span className="checkout-text">Your total is: <b>$36.75</b>.</span>
            </div>
            <div 
                className="checkout-close-btn" 
                onClick={() => setShowBookCheckoutForm(false)}>X
            </div>
        </div>;

        const bookCheckoutForm = showBookCheckoutForm ? 
        <CheckoutForm cost={36.75} item="book" />
        : 
        null;

    const eBookButton = !showEbookCheckoutForm ? 
        <PurchaseButton action={() => {
            setShowEbookCheckoutForm(!showEbookCheckoutForm);
            setShowBookCheckoutForm(false);
            setShowComboCheckoutForm(false);
        }} />
        :
        <div style={{
                display: 'flex', 
                justifyContent: 'space-between', 
                flexDirection:'row',
            }}>
            <div className="checkout-area">
                <span className="checkout-text">Sales Tax is $1.65 (8.25%)</span>
                <span className="checkout-text">Your total is: <b>$21.60</b>.</span>
            </div>
            <div 
                className="checkout-close-btn" 
                onClick={() => setShowEbookCheckoutForm(false)}>X
            </div>
        </div>;

    const comboButton = !showComboCheckoutForm ? 
        <PurchaseButton action={() => {
            setShowComboCheckoutForm(!showComboCheckoutForm);
            setShowBookCheckoutForm(false);
            setShowEbookCheckoutForm(false);
        }} />
        :
        <div style={{
                display: 'flex', 
                justifyContent: 'space-between', 
                flexDirection:'row',
            }}>
            <div className="checkout-area">
                <span className="checkout-text">Shipping is $4</span>
                <span className="checkout-text">Sales Tax is $3.63 (8.25%)</span>
                <span className="checkout-text">Your total is: <b>$47.58</b>.</span>
            </div>
            <div 
                className="checkout-close-btn" 
                onClick={() => setShowComboCheckoutForm(false)}>X
            </div>
        </div>;

    const comboCheckoutForm = showComboCheckoutForm ? 
        <CheckoutForm cost={47.58} item="combo" /> 
        : 
        null;

    return (
        <div className="extended-wrapper subscribe-page">
            <h1 className="header">Recipes That Work For You</h1>
            <div className="get-recipe-options">
                <div className="purchase-option">
                    <img 
                        src={sub}
                        className="sub-display"
                    />
                    <p>
                        Subscribe to Daily Dinners Free from Gluten, Dairy, and Anxiety: $4.99/month                    
                    </p>
                    <p className="purchase-detail">
                        Each month, we release 7 planned out, completely gluten and dairy free meals! The first six months will mirror the book, but there will be additional content.
                    </p>
                    <PurchaseButton action={() => setShowSubscriptionButton(!showSubscriptionButton)} />
                    {subscriptionForm}
                </div>

                <div className="purchase-option">
                    <img 
                        src={ebookCover}
                        className="book-display"
                    />
                    <p>
                        Ebook: Download Daily Dinners Free from Gluten, Dairy, and Anxiety as an ebook: $19.95
                    </p>
                    <p className="purchase-detail">Six weeks of completely gluten and dairy free meals!</p>
                    {eBookButton}
                    {eBookCheckoutForm}
                </div>

                <div className="purchase-option">
                    <img 
                        src={cover}
                        className="book-display"
                    />
                    <p>Daily Dinners Free from Gluten, Dairy, and Anxiety Cookbook: $29.95</p>
                    <p className="purchase-detail">Six weeks of completely gluten and dairy free meals!</p>
                    {bookButton}
                    {bookCheckoutForm}
                </div>

                <div className="purchase-option">
                    <img 
                        src={comboCover}
                        className="book-display"
                    />
                    <p>
                        Get the book AND ebook today at a discounted rate of $39.95!
                    </p>
                    <p className="purchase-detail">Six weeks of completely gluten and dairy free meals!</p>
                    {comboButton}
                    {comboCheckoutForm}
                </div>
            </div>
        </div>
    );
};

export default Purchase;