import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../context/AuthContext';
import './SignIn.css';
import { useHistory } from 'react-router-dom';
import ValidateEmail from '../helpers/ValidateEmail'

const SignUp = () => {
    const { signUpWithEmail, signInWithEmailLink } = useContext(Context);

    const [error, setError] = useState('');
    const [showAuthenticaing, setShowAuthenticating] = useState(true);

    const [email, setEmail] = useState(window.localStorage.getItem('emailForSignIn'));
    const [showSignUp, setShowSignUp] = useState(true);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [age, setAge] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        var userEmail = window.localStorage.getItem('emailForSignIn');
        if (!userEmail) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          userEmail = window.prompt('Please provide your email for confirmation');
        }

        signInWithEmailLink(userEmail, window.location.href);
        setShowAuthenticating(false);
    }, []);

    // const validate = () => {
    //     const validEmail = ValidateEmail(email);
    //     if (!validEmail) {
    //         setError('Please enter a valid email address');
    //         return;
    //     }
    //     if (password == '') {
    //         setError('Please enter a password');
    //         return;
    //     } else if (password.length < 6) {
    //         setError('Please enter a password with at least 6 characters');
    //         return;
    //     }
    //     if (password != confirmPassword) {
    //         setError('Please enssure your passwords match');
    //         return;
    //     }
    //     setError('');
    //     setShowSignUp(false);
    //     window.localStorage.removeItem('emailForSignIn');
    // };

    // const signUp = async () => {
    //     if (password != confirmPassword) {
    //         setError('Password does not match with confirm password');
    //         return;
    //     }

    //     const res = await signUpWithEmail(email, password, city, state, age);

    //     if (res && res.message) {
    //         setError(res.message);
    //     }
        
    //     history.push('/home');
    // };

    // const innerElement = showSignUp ? 
    //     <div className="sign-in-container sign-up-container">
    //         <div className="sign-in-fields">
    //             <input
    //                 className="email-input sign-in-input"
    //                 placeholder=" Email"
    //                 onChange={e => setEmail(e.target.value)}
    //                 value={email}
    //             ></input>
    //             <input
    //                 className="password-input sign-in-input" 
    //                 placeholder=" Password"
    //                 onChange={e => setPassword(e.target.value)}
    //                 type="password"
    //             ></input>
    //             <input
    //                 className="password-input sign-in-input"
    //                 placeholder=" Confirm Password"
    //                 onChange={e => setConfirmPassword(e.target.value)}
    //                 type="password"
    //                 onKeyDown={e => {
    //                     if (e.key == 'Enter') {
    //                         validate();
    //                     }
    //                 }}
    //             ></input>
    //         </div>
    //         <button
    //             type="button"
    //             className="sign-in-button sign-up-button"
    //             onClick={() => validate()}
    //         >Sign Up</button>
    //     </div> 
    // : 
    // <div className="sign-in-container demo-container">
    //     <div className="sign-in-fields">
    //         <div className="demo-text demo-qualifier">
    //             <p>No fields are required</p>
    //             <button
    //                 type="button"
    //                 className="sign-in-button"
    //                 onClick={() => signUp()}
    //             >Skip</button>
    //         </div>
    //         <label className="demo-text select-age-label" for="select-age">Age</label>
    //         <select className="select-age" name="select-age" onChange={e => setAge(e.target.value)}>
    //             <option value="0-18">0-18</option>
    //             <option value="19-25">19-25</option>
    //             <option value="26-34">26-34</option>
    //             <option value="35-54">35-54</option>
    //             <option value="55-64">35-64</option>
    //             <option value="65+">65+</option>
    //         </select>
    //         <input
    //             className="city-input sign-in-input" 
    //             placeholder=" City"
    //             value={city}
    //             onChange={e => setCity(e.target.value)}
    //         ></input>
    //         <input
    //             className="state-input sign-in-input"
    //             placeholder=" State"
    //             value={state}
    //             onChange={e => setState(e.target.value)}
    //         ></input>
    //     </div>
    //     <button
    //         type="button"
    //         className="sign-in-button"
    //         onClick={() => signUp()}
    //     >Submit</button>                
    // </div>;

    const content = showAuthenticaing ? 
        <p>Authenticating...</p>
    :
        <div>
            <h1 style={{ color: '#ff8c11' }}>Thank you for signing up!</h1>
            <h3>Please check your email for a link to set your password.</h3>
            <h3>For now, click on the "Calendar" button on the top navigation bar to explore your first week of recipes!</h3>
        </div>;

    return (
        <div className="signin-screen">
            {content}
            {/* <h3 className="sign-in-header">Sign Up!</h3>
            {innerElement}
            <div className="error-zone">
                {error}
            </div> */}
        </div>
    );
};

export default SignUp;