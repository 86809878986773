import React, { useState } from 'react';
import './Newsletter.css';
import PostNewsletterUnsubscribeEmail from '../firebase/PostNewsletterUnsubscribeEmail';

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const postEmail = () => {
        PostNewsletterUnsubscribeEmail(email).then(result => {
            console.log('result', result);
            if (result) {
                setMessage('We are sorry to see you go, but you have been sucessfully unsubscribed from the newsletter.')
            } else {
                setMessage('Sorry, there was a problem unsubscribing. Please try again.')
            }
        }).catch(error => {
            console.log('error posting to server:', error);
            setMessage('Sorry, there was a problem unsubscribing. Please try again.')
        });
    };

    return (
        <div className="centered-content extended-wrapper">
            <h1 className="header">Unsubscribe from the Newsletter</h1>
            <div className="newsletter-explanation">

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0, paddingTop: 0 }}>
                    <h3 className="subheader">Unsubscribe</h3>
                    <input 
                        onChange={e => setEmail(e.target.value)}
                        onKeyDown={e => {
                            if (e.key == 'Enter') {
                                postEmail();
                            }
                        }}
                        type="text" 
                        placeholder="Please provide your email address" 
                        className="newsletter-input" 
                    />
                    <button className="btn newsletter-submit-btn" onClick={() => postEmail()}>Submit</button>
                    <span className="newsletter-message">{message}</span>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;