import firebase from '../firebase';

const GetUserFromFirestore = async (uid) => {
    try {
      return await firebase.firestore()
      .collection('users')
      .where('uid', '==', uid)
      .get()
      .then((query) => {
        return query.docs[0].data();
      });
    } catch (error) {
      console.log('error getting user from firestore:', error);
      return null;
    }
};

export default GetUserFromFirestore;