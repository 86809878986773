import React, { useState, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import MagicUrl from 'quill-magic-url';
import Modal from './Modal';
import parse from 'html-react-parser';
import UploadBlog from '../firebase/UploadBlog';
import firebase from '../firebase';
import './BlogInput.css'

const header = `
<table cellpadding="5" align="center" style="
  width: 100%;
  text-align: center;
  max-width: 630px;
  background-color:white;"
>
  <tr>
    <td>
      <img
        class="banner-img"
        src="https://i.ibb.co/XXJQN9K/digest-Banner.png"
        alt="Daily Dinners Logo"
        class="newsletter-logo"
        style="box-shadow: 0px 0px 3.5px white;
              margin-bottom: 0px;
              padding-bottom: 0px;"
      />
    </td>
  </tr>

  <tr cellpadding="0">
    <td>
        <h3 style="font-style: italic;margin-top: 0px;padding-top:0px;font-weight:normal;">
            Designed to help people that are gluten and/or dairy free, and those who cook for them,
            to flourish through encouragement and connection!
        </h3>
    </td>
  </tr>
  <tr>
    <td>
`;

const footer = `
    </td>
  </tr>
</table>
<table cellpadding="5" align="center" style="
text-align: center;
max-width: 630px;
width:100%;
background-color: #dddddd;"
>            
<tr>
    <td>
        <table align="center">
        <tr align="center" style="width: 300px;">
            <td>
                <table>
                    <tr>
                        <td>
                            <a href="http://daily-dinners.com" style="text-decoration: none; font-weight: bold;">
                                <img src="https://i.ibb.co/MpdNv2b/Logo-with-Plate-04.png" alt="Daily Dinners Logo" />
                            </a>
                        </td>
                        <td>
                            <a href="http://daily-dinners.com" style="text-decoration: none; font-weight: bold; color: #623a29;">Daily Dinners Home</a>
                        </td>                                    
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td>
                <a style="text-decoration: none; font-weight: bold; color: #623a29;" href="http://daily-dinners.com/#/unsubscribe">Unsubscribe</a>
            </td>
        </tr>
    </table>
    </td>
</tr>
</table>
`;

export default () => {
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState('');
  const toggleModal = () => setModal(!modal);
  const [modalContent, setModalContent] = useState('');
  const [quillHtml, setQuillHtml] = useState('');
  const { quill, quillRef, Quill } = useQuill(
    { modules: 
      { 
        magicUrl: true,
      }
    });

    const showModal = () => {
      const content = parse(header + quillHtml + footer);

      setModalContent(content);
      toggleModal();
    };

    const submit = async () => {
      try {
        UploadBlog({
          content: quillHtml,
          createdAt: new Date(),
          title: title,         
        });

        // navigate to blogs page
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    };

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };

  const saveToServer = async (file) => {
    const body = new FormData();
    body.append('file', file);

    firebase.storage()
    .ref('blogImages/' + file.name)
    .put(file)
    .then(snapshot => {
      snapshot.ref
      .getDownloadURL()
      .then(downloadUrl => {
        insertToEditor(downloadUrl);
      });
    });
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

    if (Quill && !quill) {
      Quill.register('modules/magicUrl', MagicUrl);
    }

    useEffect(() => {
      if (quill) {
        quill.on('text-change', (delta, oldDelta, source) => {
          setQuillHtml(quill.root.innerHTML);
        });

        quill.getModule('toolbar').addHandler('image', selectLocalImage);
      }
    }, [quill]);
  
    return (
      <div class="blog-input-container">
        <Modal show={modal} 
          modalContent={modalContent} 
          close={toggleModal} 
          submit={submit} 
        />

        <input className="blog-title-input" 
          placeholder="Enter title..." 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
          type="text"></input>


        <div style={{fontWeight: 'normal'}} ref={quillRef} />
        <button className="btn blog-input-submit" onClick={() => showModal()}>
          Submit
        </button>
      </div>
    );
  };