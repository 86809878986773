import React, { useState } from 'react';
import './Newsletter.css';
import PostNewsletterEmail from '../firebase/PostNewsletterEmail';
import parse from 'html-react-parser';
import Collapsible from 'react-collapsible';

const stuff = `
<table cellpadding="5" align="center" style="
            width: 100%;
            text-align: center;
            max-width: 630px;"
        >
                <tr>
                    <td>
                        <img
                        class="banner-img"
                        src="https://i.ibb.co/XXJQN9K/digest-Banner.png"
                        alt="Daily Dinners Logo"
                        class="newsletter-logo"
                        style="
                            box-shadow: 0px 0px 3.5px white;
                            margin-bottom: 0px;
                            padding-bottom: 0px;"
                        />
                    </td>
                </tr>

                <tr cellpadding="0">
                    <td>
                        <h3 style="font-style: italic;margin-top: 0px;padding-top:0px;">
                            Designed to help people that are gluten and/or dairy free, and those who cook for them,
                            to flourish through encouragement and connection!
                        </h3>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h1 style="margin-bottom: 0px; padding-bottom: 0px;">Motivation to Cook Today</h1>
                    </td>
                </tr>
                <tr>
                    <td>
                        As people who are gluten and/or dairy free for different reasons, we have the same goal
                        - to cook dinner. Some of us have been gluten and dairy free for several years, some of
                        us are just starting our journey. Some of us are sure that it’s gluten and dairy that are
                        our problem, some of us are just figuring out the problem. Whatever the case, we need
                        to eat. Most days we only need a little inner prompt to cook dinner and take care of
                        ourselves and those around us. But there are some days we need an outer voice as
                        well. I wanted to share with you some encouragement that I’ve received through the
                        years from friends, real people I know, that have encouraged me. I replay these
                        encouraging words in my head on the days that I need that outer voice to get me going
                        in the kitchen.
                    </td>
                </tr>

                <tr>
                    <table cellpadding="10" align="center">
                        <tr>
                            <td>
                                <img
                                src="https://i.ibb.co/Ln1HMZW/digest1-Orange.png" 
                                alt="Daily Dinners Logo"
                                class="newsletter-logo"
                                style="width: 225px;
                                    height: 225px;
                                    box-shadow: 0px 0px 3.5px white;
                                    margin-bottom: 0px;
                                    padding-bottom: 0px;"
                                />
                            </td>
        
                            <td style="text-align: center;">
                                <img
                                src="https://i.ibb.co/cFqrhsp/digest1.png" 
                                alt="Daily Dinners Logo"
                                class="newsletter-logo"
                                style="width: 225px;
                                    height: 225px;
                                    box-shadow: 0px 0px 3.5px white;
                                    margin-bottom: 0px;
                                    padding-bottom: 0px;"
                                />
                            </td>
                        </tr>
        
                        <tr>
                            <td style="font-weight: bold; text-align: center;">
                                Lisa, Homemaker, Houston, Texas
                            </td>
                            <td style="font-weight: bold; text-align: center;">
                                Bill, Cardiologist, Des Moines, Iowa
                            </td>
                        </tr>                        

                        <tr>
                            <td style="text-align: center;">
                                <img
                                src="https://i.ibb.co/6Hh0mFC/digest1-Jill.png" 
                                alt="Daily Dinners Logo"
                                class="newsletter-logo"
                                style="width: 225px;
                                    height: 225px;
                                    box-shadow: 0px 0px 3.5px white;
                                    margin-bottom: 0px;
                                    padding-bottom: 0px;"
                                />
                            </td>
        
                            <td style="text-align: center;">
                                <img
                                    src="https://i.ibb.co/tCD4R3K/digest1-Mary.png" 
                                    alt="Daily Dinners Logo"
                                    class="newsletter-logo"
                                    style="width: 225px;
                                    height: 225px;
                                            box-shadow: 0px 0px 3.5px white;
                                            margin-bottom: 0px;
                                            padding-bottom: 0px;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold; text-align: center;">
                                Jill, Child Advocate, Sugar Land, Texas
                            </td>
                            <td style="font-weight: bold; text-align: center;">
                                Mary Anne, Friend, Indianapolis, IN
                            </td>
                        </tr>
                    </table>
                </tr>

                <tr>
                    <table cellpadding="10" align="center" style="width:100%;max-width: 630px;">
                        <tr>
                            <td>
                                Whether you practically state the truth, like my friend Lisa, “You are going to have to
                                cook,” or you are having friends and family over like my friend Mary Anne, “And, to
                                accomplish my goal, I cook!,” or are somewhere in between, we are going to make
                                dinner tonight.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                I’m so excited that you are reading this first Daily Dinners Digest. I hope that 
                                reading some of my friends’ words to me helps you motivate you this week. Have you 
                                tried any of the <a style="text-decoration: none; font-weight: bold; color: black;" href="http://daily-dinners.com/#/try-it-free">Try It Out For Free!</a> recipes yet? Those are extra recipes that aren’t 
                                in the cookbook. Try one of them this week and see that you really can make a good 
                                dinner free from gluten and dairy quite easily. Do you have something special that 
                                prompts you to cook? If so, email me at laura@daily-dinners.com to share your inspiration.
                            </td>
                        </tr>
                    </table>
                </tr>
        </table>
    </table>
`;

const stuff2 = `
<table cellpadding="5" align="center" style="
width: 100%;
text-align: center;
max-width: 630px;"
>
    <tr>
        <td>
            <img
            class="banner-img"
            src="https://i.ibb.co/XXJQN9K/digest-Banner.png"
            alt="Daily Dinners Logo"
            class="newsletter-logo"
            style="
                box-shadow: 0px 0px 3.5px white;
                margin-bottom: 0px;
                padding-bottom: 0px;"
            />
        </td>
    </tr>

    <tr cellpadding="0">
        <td>
            <h3 style="font-style: italic;margin-top: 0px;padding-top:0px;">
                Designed to help people that are gluten and/or dairy free, and those who cook for them,
                to flourish through encouragement and connection!
            </h3>
        </td>
    </tr>
    <tr>
        <td>
            <h1 style="margin-bottom: 0px; padding-bottom: 0px;">Herbs, Part 1 - The Art of Finely Chopping</h1>
        </td>
    </tr>
    <tr>
        <td>
            If you are new to using fresh herbs, you may be wondering how you can easily finely chop them.  You can do almost anything in the kitchen with a knife, it’s true, but is that the easiest and fastest in this case? <a style="color:black;font-weight: bold;font-style: italic;" href="https://daily-dinners.com/#/purchase"> In Week 1, on Monday</a>, chopped fresh herbs are in the ingredient list for both the chicken and the spinach. I use a couple of kitchen tools that I didn’t list on my kitchen tools list to finely chop fresh herbs.  I didn’t include them because they aren’t necessary, but I want to tell you about them because they make finely chopping herbs so much easier and faster!
        </td>
    </tr>

    <tr>
        <table cellpadding="10" align="center">
            <tr>
                <td>
                    <img
                    src="https://i.ibb.co/Dgg9zxS/Herbs-P1-1.png" 
                    alt="Daily Dinners Logo"
                    class="newsletter-logo"
                    style="width: 225px;
                        height: 225px;
                        box-shadow: 0px 0px 3.5px white;
                        margin-bottom: 0px;
                        padding-bottom: 0px;"
                    />
                </td>
            </tr>
            <tr>
                <td style="width:275px; vertical-align: top;">
                    On the left is a tool called the herb zipper, on the right is a tool called a double mezzaluna with it’s own special cutting board.  I bought the herb zipper for less than $10 at a kitchen specialty store.  I bought my mezzaluna set many years ago at a European big box store for a very reasonable price.
                </td>
            </tr>
        </table>
    </tr>

    <tr>
        <table cellpadding="10" align="center">
            <tr>
                <td>
                    <img
                    src="https://i.ibb.co/YjWS5SL/Herbs-P1-2.png" 
                    alt="Daily Dinners Logo"
                    class="newsletter-logo"
                    style="width: 225px;
                        height: 225px;
                        box-shadow: 0px 0px 3.5px white;
                        margin-bottom: 0px;
                        padding-bottom: 0px;"
                    />
                </td>

                <td style="text-align: center;">
                    <img
                    src="https://i.ibb.co/tYzxVbF/Herbs-P1-3.png" 
                    alt="Daily Dinners Logo"
                    class="newsletter-logo"
                    style="width: 225px;
                        height: 225px;
                        box-shadow: 0px 0px 3.5px white;
                        margin-bottom: 0px;
                        padding-bottom: 0px;"
                    />
                </td>
            </tr>

            <tr>
                <td style="width:275px; vertical-align: top;">
                    In order to chop herbs that have leaves that grow down the stem, i.e., oregano, thyme, rosemary, basil, you need to first pull the leaves off of the stem.  You can see that I’ve pulled part of the oregano through the zipper.  The leaves fall into the bowl, and the stem can be discarded.  This is extremely fast and easy.                            </td>
                <td style="width:275px; vertical-align: top;">
                    Equally as speedy, I then use my double mezzaluna to finely chop the oregano leaves.  After a while, you get pretty good at estimating the amount of herb you need to wash to chop for the amount in a given recipe.                            </td>
            </tr>                        

            <tr>
                <td style="text-align: center;">
                    <img
                    src="https://i.ibb.co/FqsFv5S/Herbs-P1-4.png" 
                    alt="Daily Dinners Logo"
                    class="newsletter-logo"
                    style="width: 225px;
                        height: 225px;
                        box-shadow: 0px 0px 3.5px white;
                        margin-bottom: 0px;
                        padding-bottom: 0px;"
                    />
                </td>

                <td style="text-align: center;">
                    <img
                        src="https://i.ibb.co/0JktpWW/Herbs-P1-5.png" 
                        alt="Daily Dinners Logo"
                        class="newsletter-logo"
                        style="width: 225px;
                        height: 225px;
                                box-shadow: 0px 0px 3.5px white;
                                margin-bottom: 0px;
                                padding-bottom: 0px;"
                    />
                </td>
            </tr>
            <tr>
                <td style="width:275px; vertical-align: top;">
                    When I have herbs that have fairly long stems away from the leaves I am chopping, i.e., dill, parsley, cilantro, I use only the mezzaluna.  Here you can see that I’ve washed the dill and worked to get the long extra parts of the stems in a line away from the leafy part of the herb that I want to chop.  Then I can start the chopping process by chopping off and discarding this part of the stem.
                </td>
                <td style="width:275px; vertical-align: top;">
                    Here is the mezzaluna process as I’ve started chopping the part of the dill I wish to finely chop.  In almost no time at all, the dill will be ready for the recipe.</td>
            </tr>
        </table>
    </tr>

    <tr>
        <table cellpadding="10" align="center" style="width:100%;max-width: 630px;">
            <tr>
                <td>
                    I find that using fresh herbs makes everyday dinners taste better by providing a freshness and a certain oiliness that only comes from the herbs.  Fresh herbs are considered to be quite good for your health.  If you google the benefits of eating fresh herbs, you will be excited to add them to your life if you haven’t already.  Now that you know there is an Art to Finely Chopping Herbs, you will find them fairly easy to add to your life.  So, now you can get chopping!  Enjoy your day, and enjoy today’s Daily Dinner!
                </td>
            </tr>
        </table>
    </tr>
</table>
`;

const stuff3 = `
<!DOCTYPE html>

<html>
    <head>
        <style>
            .stripe {
  color: white;
  background: linear-gradient(
    to bottom,
    #623a29,
    #623a29 50%,
    #29cccf 50%,
    #29cccf
  );
  background-size: 100% 45px;
} 
.banner-img {
    width: 100%;
}

@media(max-width: 540px) {
    .banner-img {
        /* width: 80%; */
        /* height: 150px; */
    }
}
        </style>
    </head>
    <body>
        <table cellpadding="5" align="center" style="
            width: 100%;
            text-align: center;
            max-width: 630px;"
        >
                <tr>
                    <td>
                        <img
                        class="banner-img"
                        src="https://i.ibb.co/XXJQN9K/digest-Banner.png"
                        alt="Daily Dinners Logo"
                        class="newsletter-logo"
                        style="
                            box-shadow: 0px 0px 3.5px white;
                            margin-bottom: 0px;
                            padding-bottom: 0px;"
                        />
                    </td>
                </tr>

                <tr cellpadding="0">
                    <td>
                        <h3 style="font-style: italic;margin-top: 0px;padding-top:0px;">
                            Designed to help people that are gluten and/or dairy free, and those who cook for them,
                            to flourish through encouragement and connection!
                        </h3>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h1 style="margin-bottom: 0px; padding-bottom: 0px;">Roux and Stew</h1>
                    </td>
                </tr>
                <tr>
                    <td>
                        The word roux conjures up memories of deep flavors and gumbo for many of us. But, gumbo
                        isn’t the first and last place roux is used. Roux is defined as a mixture of cooked fat and flour
                        used to thicken gravies and stews. In <a href="https://daily-dinners.com/#/purchase" style="color:black;font-weight: bolder;font-style: italic;">Week 1, on Tuesday,</a> you make a roux for the base of the
                        gravy for the Turkey Pot Pie. In <a href="https://daily-dinners.com/#/purchase" style="color:black;font-weight: bolder;font-style: italic;">Week 6, on Friday,</a> you make and use a roux in a manner less often thought
                        of for the Everyday Wild Rice Soup. I thought I could share a few pictures of making a roux to show you
                        how easy it really is to make a roux and add a depth of flavor to your Daily Dinners.
                    </td>
                </tr>

                <tr>
                    <table cellpadding="10" align="center" style="width:100%;max-width: 630px;">
                        <tr>
                            <td>
                                <img
                                src="https://i.ibb.co/MN8wRx7/Roux1.png" 
                                alt="Daily Dinners Logo"
                                class="newsletter-logo"
                                style="width: 225px;
                                    height: 225px;
                                    box-shadow: 0px 0px 3.5px white;
                                    margin-bottom: 0px;
                                    padding-bottom: 0px;"
                                />
                            </td>
        
                            <td style="text-align: center;">
                                <img
                                src="https://i.ibb.co/94KCVZx/Roux2.png" 
                                alt="Daily Dinners Logo"
                                class="newsletter-logo"
                                style="width: 225px;
                                    height: 225px;
                                    box-shadow: 0px 0px 3.5px white;
                                    margin-bottom: 0px;
                                    padding-bottom: 0px;"
                                />
                            </td>
                        </tr>
        
                        <tr>
                            <td style="text-align: center;border-right:1px solid black;">
                                This is a measurement of canola oil. I wanted you to see how clear the canola oil is before
                                cooking it, as well as have you take a good look at the color of the canola oil. Making roux
                                requires not only constant stirring, but noticing and focusing on the color of the roux as it
                                progresses.
                            </td>
                            <td style="text-align: center;">
                                Here is the canola oil mixed with a 1-to-1 gluten free baking flour before heat is applied. Note
                                the light color. Note, too, that the whisk is not the only utensil being used to stir the roux. I love
                                to use a wooden spoon that has a hole in it to stir the roux.
                            </td>
                        </tr>                        

                        <tr>
                            <td style="text-align: center;">
                                <img
                                src="https://i.ibb.co/5sr0x2L/Roux3.png" 
                                alt="Daily Dinners Logo"
                                class="newsletter-logo"
                                style="width: 225px;
                                    height: 225px;
                                    box-shadow: 0px 0px 3.5px white;
                                    margin-bottom: 0px;
                                    padding-bottom: 0px;"
                                />
                            </td>
        
                            <td style="text-align: center;">
                                <img
                                    src="https://i.ibb.co/b2RQJWn/Roux4.png" 
                                    alt="Daily Dinners Logo"
                                    class="newsletter-logo"
                                    style="width: 225px;
                                    height: 225px;
                                            box-shadow: 0px 0px 3.5px white;
                                            margin-bottom: 0px;
                                            padding-bottom: 0px;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: center; border-right: 1px solid black;">
                                Here is the roux at the light golden stage of cooking. Although you are cooking roux over a low
                                heat, you have to constantly stir it so that it doesn’t burn and the color is unified as you stir the
                                roux. It’s true that you will get your main color for roux in about 5 minutes, so set your timer.
                                But, you have to stir constantly and keep an eye on the roux, because this is an instance of
                                cooking by color, not the timer.
                            </td>
                            <td style="text-align: center;">
                                Once it gets to the color described in the recipe, take it off the heat so that it doesn’t get too
                                dark. Notice that the roux has not only changed color, but has thickened up so that you can
                                “part it” and see the bottom of the pan shining through. This is a dark golden/light chocolate
                                color.
                            </td>
                        </tr>
                    </table>
                </tr>

                <tr>
                    <table cellpadding="10" align="center" style="width:100%;max-width: 630px;">
                        <tr>
                            <td>
                                Now you can proceed with the rest of the recipe, knowing that you’ve made a roux! Baker’s
                                secret: Anything that a mom has made for years and years and years might have a mystique
                                about it, but is generally easy for you to make, too.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Beginning your gravy with a roux is easy and adds a depth of flavor and color that you can’t get
                                any other way. Some recipes call for adding aromatics to the roux as you are cooking it. The
                                Everyday Wild Rice recipe calls for making a roux separately and adding it to the dish near the
                                end because of the way the wild rice cooks. Always follow the instructions for each recipe.
                                There really is a method when making roux. Once you’ve made the Turkey Pot Pie and
                                Everyday Wild Rice soup, you should have the technique for making a roux for stew conquered.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                And, once you’ve conquered roux and you want to try to make a Seafood Gumbo, google Poppy
                                Tooker’s recipe. That’s the recipe that I always use to make gumbo. Instead of following the
                                instructions exactly, however, replace the flour called for measure for measure with a 1-to-1
                                gluten free baking flour. Enjoy your day, and enjoy today’s Daily Dinner!
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Always feel free to reply to this email with questions or comments!
                            </td>
                        </tr>
                    </table>
                </tr>
        </table>
        <table cellpadding="5" align="center" style="
            text-align: center;
            max-width: 630px;
            width:100%;
            background-color: #dddddd;"
        >            
            <tr>
                <td>
                    <table align="center">
                    <tr align="center" style="width: 300px;">
                        <td>
                            <table>
                                <tr>
                                    <td>
                                        <a href="http://daily-dinners.com" style="text-decoration: none; font-weight: bold;">
                                            <img src="https://i.ibb.co/MpdNv2b/Logo-with-Plate-04.png" alt="Daily Dinners Logo" />
                                        </a>
                                    </td>
                                    <td>
                                        <a href="http://daily-dinners.com" style="text-decoration: none; font-weight: bold; color: #623a29;">Daily Dinners Home</a>
                                    </td>                                    
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a style="text-decoration: none; font-weight: bold; color: #623a29;" href="http://daily-dinners.com/#/unsubscribe">Unsubscribe</a>
                        </td>
                    </tr>
                </table>
                </td>
            </tr>
        </table>
    </body>
</html>
`;

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const postEmail = () => {
        PostNewsletterEmail(email).then(result => {
            console.log('result', result);
            if (result) {
                setMessage('Thanks, you will now receive our weekly newsletters!')
            } else {
                setMessage('Sorry, there was a problem signing up. Please try again.')
            }
        }).catch(error => {
            console.log('error posting to server:', error);
            setMessage('Sorry, there was a problem signing up. Please try again.')
        });
    };

    return (
        <div className="centered-content extended-wrapper">
            <h1 className="header">Newsletter</h1>
            <div className="newsletter-explanation">
                {/* I am not a nurse, nutritionist, or a professional chef.  
                I have been a teacher for most of my life.  
                But, when our lifestyle changed, I needed to become a student again.   */}
                {/* And, I can tell you that through trial and error,  */}
                
                <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                    When my family's lifestyle changed, I worked hard to craft a world of normal, daily foods for us that fit our new lifestyle. 
                    You may be in the trial and error phase yourself. 
                    I’m offering you a starting place, far ahead of where we were when we first encountered the gluten free and dairy free lifestyle. 
                    Along the way, I’ve learned a lot of things about cooking, the kitchen, and the gluten and dairy free lifestyle.
                </p>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0, paddingTop: 0 }}>
                    <h3 className="subheader">Sign Up Here!</h3>
                    <input 
                        onChange={e => setEmail(e.target.value)}
                        onKeyDown={e => {
                            if (e.key == 'Enter') {
                                postEmail();
                            }
                        }}
                        type="text" 
                        placeholder="Please provide your email address" 
                        className="newsletter-input" 
                    />
                    <button className="btn newsletter-submit-btn" onClick={() => postEmail()}>Submit</button>
                    <span className="newsletter-message">{message}</span>
                </div>

                <p>
                    If you sign up for my free weekly newsletter, I will share with you what I’ve learned, little by little. 
                    You might already know what I've learned, but it's really nice to be in a dialog with someone and not feel alone.  Also, the Newsletter will announce if there are any special weeks on the website that are not included in the book, so please sign up for the Newsletter today!
                </p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', fontWeight: 'normal' }}>
                <h3>Previous Newsletters</h3>
                <Collapsible trigger="Digest 1: Motivation to Cook">
                    {parse(stuff)}
                </Collapsible>

                <br />
                <br />
                <br />

                <Collapsible trigger="Digest 2: Herbs, Part 1 - The Art of Finely Chopping">
                    {parse(stuff2)}
                </Collapsible>

                <br />
                <br />
                <br />

                <Collapsible trigger="Digest 3: Roux and Stew">
                    {parse(stuff3)}
                </Collapsible>
            </div>
        </div>
    );
};

export default Newsletter;