import React, { useContext } from 'react';
import './SideDrawer.css'
import Week from './Week';
import { Context } from '../context/AuthContext';
import { Link } from 'react-router-dom';

const SideDrawer = ({ isOpen, close }) => {
    const { state } = useContext(Context);
    const user = state.user;

    let calendarRows = null;
    if (user) {
        calendarRows = [];
        for (let i = 0; i < user.subscribed; i++) {
            const weekNumber = (i + 1).toString();
            calendarRows.push(
                <Week number={weekNumber} close={close} />
            );
        }
        for (let i = user.subscribed; i < 6; i++) {
            const weekNumber = (i + 1).toString();
            calendarRows.push(
                <Week number={weekNumber} close={close} disabled={true} />
            );
        }
    } else {
        calendarRows = <span className="non-member-text">
            Please try the recipes here on our website. They are done in the exact same manner as the book, Daily 
            Dinners: Gluten, Dairy, and Anxiety Free, Comfort Food Done Differently.  Here you have a simple Sunday, 
            but it's expandable if you want it to be, a typical Monday and Tuesday.  If you like these, subscribe for 
            a monthly fee.  Each month, seven new dinners will drop (Sunday – Saturday), and you can take the month to 
            try them.  If you'd like all of the dinners at once, you can buy the book<Link id="purchase" to='/purchase'>Here!</Link></span>
    }

    const className = isOpen ? "side-drawer open" : "side-drawer";
    return (
        <nav className={className}>
        <div className="week-options">
            {/* <h2>
                {/* add an asterisk
                before and after Calendar 
                Calendar
            </h2> 
            */}
            <div className="week-container">
                <span className="week-title">Complimentary Recipes</span>
                <div className="complimentary-recipes">
                    <Link to='/Complimentary-Meal/Complimentary-Sunday' onClick={close} className="sunday-side-drawer">Su</Link>
                    <Link to='/Complimentary-Meal/Complimentary-Monday' onClick={close} className="monday-side-drawer">M</Link>
                    <Link to='/Complimentary-Meal/Complimentary-Tuesday' onClick={close} className="tuesday-side-drawer">Tu</Link>
                </div>
            </div>
            {calendarRows}
            <li>
            </li>
        </div>
    </nav>
    );
};

export default SideDrawer;