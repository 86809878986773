import { firebase } from '@firebase/app'
import '@firebase/auth';
import '@firebase/firestore';
import 'firebase/storage'

  // Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAoaHGKtdYoEkDTJML4x0Chx94EC8H8Eeg",
    authDomain: "daily-dinners.firebaseapp.com",
    databaseURL: "https://daily-dinners.firebaseio.com",
    projectId: "daily-dinners",
    storageBucket: "daily-dinners.appspot.com",
    messagingSenderId: "527003783978",
    appId: "1:527003783978:web:dd87e8f212fb6f8dd04453",
    measurementId: "G-91PHLTYXSK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;