import React, { useContext, useEffect } from 'react';
import { Context } from '../context/AuthContext';
import './Home.css';
import { Link } from 'react-router-dom';

const Home = () => {
    const { checkAuth } = useContext(Context);
    useEffect(() => {
        checkAuth();
    }, []);

    const frontCover = require('../assets/images/Front Cover.png');

    return (
        <div className="home-content wrapper">
            <div className="row">
                <div className="book-area-home">
                    <img
                        src={frontCover}
                        className="background-stripes"
                        alt="Raindow stripes of Daily Dinner's"
                    ></img>
                    <p>
                        The book is composed of the recipes for six full weeks worth of completely gluten and dairy free meals!
                    </p>
                    <button className="home-buy-btn btn">
                        <Link to="/purchase">
                            Click Here to Buy!
                        </Link>
                    </button>
                    {/* <p>
                        You can also purchase a subscription to receive 7 new recipes each month.
                    </p> */}
                </div>
                <div className="about-area-home">
                    <h1 className="header home-header">Daily Dinners: Free of Gluten, Dairy and Anxiety</h1>
                    <h3 className="subheader home-subheader">Comfort Food Done Differently </h3>
                    <p>
                        When you’ve had to radically change your diet from eating anything to eating gluten and dairy free, 
                        it’s easier if you can draw on some comfort foods of your past while you learn about new foods in 
                        your future.  Daily Dinners takes the guesswork out of the most relentless meal of the day - dinner. 
                        Here you will have different daily dinners for six weeks straight from me, a cook who has learned her 
                        homegrown wisdom through trial and error.  What I’ve learned by creating my recipes then becomes a 
                        starting point for your own dietary decisions.
                    </p>
                    <h3 className="home-subheader subheader">How It All Works</h3>
                    <p>
                        Each night, the first recipe contains an order of dinner preparation, which tells you when to 
                        make the side dishes in relation to the main dish.  This will help you have a dinner in which 
                        everything turns out at approximately the same time and you don't forget to make anything.  
                        Each Daily Dinner produces a dinner for four.  All of the recipes tell you what kitchen tools 
                        you need and how much time each recipe takes.  The first recipe, with the order of dinner 
                        preparation, will have you making recipes simultaneously, so don’t be overwhelmed if you’re 
                        adding up the times for each recipe.  The truth is that most dinners are ready in an hour or so. 
                        Sundays are either “expandable” or “super simple,” because we need both.  “Expandable” recipes 
                        are dinners you can invite friends and family over to eat with you so you should be able to double 
                        or triple easily to make enough for everyone.  “Super simple” Sundays are designed so that you 
                        can have a laid back Sunday evening.  Saturday Dinners assume that you might have a bit more 
                        prep/cook time.  Weeknight dinners tend to be easier and time sensitive since we work Monday – Friday.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Home;