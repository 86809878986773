import React, { useEffect } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import AddOrder from "../firebase/AddOrder";
import AddPayer from "../firebase/AddPayer";
import { useHistory } from 'react-router-dom';
import SendEmail from "../helpers/SendEmail";
import SendShippingInfo from '../helpers/SendShippingInfo';

const emailWrapper = async (name, email) => {
  await SendEmail(name, email);
};

const CheckoutForm = ({ cost, item }) => {  
  let history = useHistory();

  return (
    <div id="payment-form-wrapper">
      <div id="payment-form-container">
        <PayPalButton
          amount={cost}
          shippingPreference="GET_FROM_FILE"
          onSuccess={(details, data) => {
            try {
              const payer = details.payer;
              const address = details.purchase_units[0].shipping.address;
              const payPalOrderId = data.orderID;
              const email = payer.email_address;
              const name = payer.name;
              const order = { email, name, address, payPalOrderId, cost, item, date: new Date() };

              if (item == 'ebook') {
                emailWrapper(name.given_name, email);
              } else if (item == 'book') {
                const fullName = name.given_name + ' ' + name.surname;
                SendShippingInfo(fullName, address);
              } else if (item == 'combo') {
                emailWrapper(name.given_name, email);
                const fullName = name.given_name + ' ' + name.surname;
                SendShippingInfo(fullName, address);
              }
  
              AddOrder(order);
              AddPayer(email);

              history.push('/thanks/' + item);
            } catch (e) {
              console.log('e', e);
            }
            // alert("Thank you, " + details.payer.name.given_name + '! We will notify you via email when your order ships.');
  
            // OPTIONAL: Call your server to save the transaction
            // return fetch("/paypal-transaction-complete", {
            //   method: "post",
            //   body: JSON.stringify({
            //     orderId: data.orderID
            //   })
            // });
          }}
          options={{
            // clientId: "AeWJEYqd2g_ngFVRl2NWHaJBDB-TWP4uRdzMrrkPRz70uArSxpHyzIh-OT_12Niuj6ZI9Yqy1A-OtNuR" // testing
            clientId: "AdAr45Mo_wgg0pAplTQbNx69vsynubiXt1b_EArobjcBpe2GIecrMZyyDuIhZIG5QlUwITsb4XisT1zf" // production
          }}
        />
      </div>
    </div>
  );
};

export default CheckoutForm;