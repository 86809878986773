import firebase from '../firebase';

export default async function (email) {
    await firebase.auth().signInAnonymously().then(async (res) => {
        const emailExists = await firebase
        .firestore()
        .collection('newsletterEmails')
        .where('email', '==', email)
        .get()
        .then((query) => {
            const doc = query.docs[0];
            if (doc) {
                return query.docs[0].data();
            } else {
                return null;
            }
        });

    if (!emailExists) {
        await firebase.firestore()
        .collection('newsletterEmails')
        .add({ email });
    }
    });
};