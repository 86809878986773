import firebase from '../firebase';

export default async function() {
    try {
        return await firebase.auth().signInAnonymously().then(async (res) => {
            return await firebase
            .firestore()
            .collection('emailCredentials')
            .get()
            .then((query) => {
                let data = [];
                query.docs.map(doc => {
                    data.push(doc.data());
                });
                return data;
            });
        })
    } catch (error) {
    console.log('error getting email credentials from firestore:', error);
    return error;
  }
}