import React from 'react';
import { Link } from 'react-router-dom';

const TryItFreeDay = ({ day }) => {
    const link = "/Complimentary-Meal/Complimentary-" + day;
    return (
            <Link className={"try-it-free-day try-it-free-" + day} to={link}>
                <h3>
                    {day}
                </h3>
            </Link>
    );
};

export default TryItFreeDay;