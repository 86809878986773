import parse from 'html-react-parser';

const Read = (mealWordList) => { 
    const finder = mealWordList.join('-');

    const files = require('../assets/jsons/' + finder + '.json');

    let recipes = [];
    let titles = [];
    
    files.map((file) => {
        recipes.push(parse(file.recipe));
        titles.push(file.title);
    });
    
    return { recipes, titles };
};

export default Read;