import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Read from '../helpers/Read';
import TitleElement from './TitleElement'
import RecipeBackdrop from './RecipeBackdrop';
import { Context } from '../context/AuthContext';

const data = require('../assets/meal-titles.json');
const titles = Object.keys(data);

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const searchFor = (keyword, user) => {
    let weeks = [];
    for (var i = 0; i < user.subscribed; i++) {
        const idx = i + 1;
        weeks.push(idx.toString());
    }

    daysOfWeek.map((day) => {
        weeks.map(week => {
            const mealWordList = ['Week', week, day];
            const { recipes, titles } = Read(mealWordList);
            titles.map((title, index) => {
                data[title] = recipes[index];
            });
        });
    });

    return titles.filter(t => t.toLowerCase().includes(keyword.toLowerCase()) && data[t]);
};

const SearchResults = () => {
    let { search } = useParams();

    const { state } = useContext(Context);
    const user = state.user;

    const [titleElements, setTitleElements] = useState([]);
    const [activeRecipeIndex, setActiveRecipeIndex] = useState(0);
    const [showRecipe, setShowRecipe] = useState(false);
    const [filteredTitles, setFilteredTitles] = useState([]);
    const [keyword, setKeyword] = useState(search);

    const setup = (search, user) => {
        const filtered = searchFor(search, user);

        setFilteredTitles(filtered);
        
        const starbust = require('../assets/images/starburst.png');

        const theTitleElements = filtered.map((title, index) => {
            return ( 
                <div className="recipe-row">
                    <img src={starbust} className="starburst"></img>
                    <TitleElement 
                        onClick={() => {
                            setActiveRecipeIndex(index);
                            showARecipe(showRecipe);
                        }}
                        title={title}
                        dayOfWeek="Website"
                    />
                </div>
                );
        });
        setTitleElements(theTitleElements);
    };

    if (keyword != search) {
        setKeyword(search);
        setup(search, user);
    }

    useEffect(() => {
        setup(search, user);
    }, []);

    const showARecipe = (isOpen) => {
        const newIsOpen = !isOpen;
        setShowRecipe(newIsOpen);
    };
    
    const hideRecipe = () => {
        setShowRecipe(false);
    };

    const selectedTitle = filteredTitles[activeRecipeIndex];
    const recipe = showRecipe ? data[selectedTitle] : null;
    const closeButton = recipe ? 
        <div className="close-btn" onClick={() => hideRecipe()}>X</div>
        :
        null;

    return (
        <div className="search-results">
            <div className="recipe-content">
                {recipe}
                {closeButton}
            </div>
            {recipe}
            <RecipeBackdrop isOpen={showRecipe} onClick={hideRecipe} />
            <br></br>
            <h1 className="search-header">Results</h1>
            <div className="title-elements">
                {titleElements}
            </div>
        </div>
    );
};

export default SearchResults;