import firebase from '../firebase';

const GetNewsletterEmails = async () => {
    try {
      return await firebase.auth().signInAnonymously().then(async (res) => {
        return await firebase.firestore()
        .collection('newsletterEmails')
        .get()
        .then((query) => {
          return query.docs.map(doc => {
              return doc.data();
          });
        });  
      });
    } catch (error) {
      console.log('error getting user from firestore:', error);
      return null;
    }
};

export default GetNewsletterEmails;