import React from 'react';
import './TryItFree.css';
import TryItFreeDay from '../components/TryItFreeDay';

const TryItFree = () => {
    return (
        <div className=" centered-content">
            <h1 className="header">Try It Out For Free</h1>
            <div className="try-it-free-text-explanation">
                I’m offering you a starting place, far ahead of where we were when we first encountered the gluten free and dairy free lifestyle. 
                Yet, before you buy the book or subscribe to the website, please try these meals to see if this is something that is helpful to you or not. 
                Each of these meals is designed just like the meals in the book or on the website. 
                Don’t be afraid to print them out and make notes on them or on your computer. 
                It’s the best way for you to note what dinners you do and don’t like, and changes that you might need to make to any recipe for your own individual health issues.
            </div>
            <div className="try-it-free-days">
                <TryItFreeDay day="Sunday" />
                <TryItFreeDay day="Monday" />
                <TryItFreeDay day="Tuesday" />
            </div>
        </div>
    );
};

export default TryItFree;