import React, { useState, useEffect, useRef } from "react";
import './Modal.css';

const Modal = ({ show, modalContent, close, submit }) => {
    const ref = useRef();

    useOnClickOutside(ref, () => close());

    return !show ? null : (
      <div ref={ref} className="modal-container" style={{zIndex:9999999999}}>
        <div className="modal-header">
            <span onClick={() => close()} className="modal-close">X</span>
        </div>
        <div className="modal-body">
          {modalContent}
        </div>
        <div className="modal-footer">
            <button className="modal-submit btn" onClick={() => {
                submit();
                close();
              }}>
              Submit
            </button>
        </div>
      </div>
    );
  };
  
  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler();
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because the passed-in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  export default Modal;