import React, { useState, useEffect, useContext } from 'react';
import RecipeBackdrop from './RecipeBackdrop';
import TitleElement from './TitleElement';
import Read from '../helpers/Read';
import './recipe.css';
import './recipes.css';
import { Context } from '../context/AuthContext';

const data = require('../assets/meal-titles.json');

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Recipes = () => {
    const titles = Object.keys(data);
    const [titleElements, setTitleElements] = useState([]);
    const [activeRecipeIndex, setActiveRecipeIndex] = useState(0);
    const [showRecipe, setShowRecipe] = useState(false);
    const { state } = useContext(Context);

    useEffect(() => {
        const user = state.user;
    
        let weeks = ['Complimentary'];
        for (var i = 0; i < user.subscribed; i++) {
            const idx = i + 1;
            weeks.push(idx.toString());
        }
        
        daysOfWeek.map((day, dayIndex) => {
            weeks.map(week => {
                let mealWordList = [];
                if (week == 'Complimentary') {
                    if (dayIndex > 2) {
                        return;
                    }
                    mealWordList = [week, day];
                } else {
                    mealWordList = ['Week', week, day];
                }
                const { recipes, titles } = Read(mealWordList);
                titles.map((title, index) => {
                    data[title] = recipes[index];
                });
            });
        });

        const starbust = require('../assets/images/starburst.png');

        const theTitleElements = titles.map((title, index) => {
            if (data[title]) {
                return ( 
                <div className="recipe-row">
                    <img src={starbust} className="starburst"></img>
                    <TitleElement 
                        onClick={() => {
                            setActiveRecipeIndex(index);
                            showARecipe(showRecipe);
                        }}
                        title={title}
                        dayOfWeek="Website"
                    />
                </div>
                );
            } else {
                console.log('missing', title);
            }
        });
        setTitleElements(theTitleElements);
    }, []);

    const showARecipe = (isOpen) => {
        setShowRecipe(!isOpen);

        const body = document.querySelector('#root');

        body.scrollIntoView({
            behavior: 'smooth'
        }, 500);
    };
    
    const hideRecipe = () => {
        setShowRecipe(false);
    };

    const selectedTitle = titles[activeRecipeIndex];
    const recipe = showRecipe ? data[selectedTitle] : null;
    const closeButton = recipe ? 
        <div className="close-btn" onClick={() => hideRecipe()}>X</div>
        :
        null;

    return (
        <div className="recipes-wrapper">
            <div className="recipe-content">
                {recipe}
                {closeButton}
            </div>
            <RecipeBackdrop isOpen={showRecipe} onClick={hideRecipe} />
            <br></br>
            <h1 className="recipes-header">All Recipes</h1>
            <div className="title-elements">
                {titleElements}
            </div>
        </div>
    );
};

export default Recipes;