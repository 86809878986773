import React from 'react';
import { Link } from 'react-router-dom';

const Week = ({ number, close, disabled = false }) => {
    if (!disabled) {
        const sunday = `/meal/Week~${number}~Sunday`;
        const monday = `/meal/Week~${number}~Monday`;
        const tuesday = `/meal/Week~${number}~Tuesday`;
        const wednesday = `/meal/Week~${number}~Wednesday`;
        const thursday = `/meal/Week~${number}~Thursday`;
        const friday = `/meal/Week~${number}~Friday`;
        const saturday = `/meal/Week~${number}~Saturday`;
        
        return (
            <div className="week-container">
                <span className="week-title">Week {number}</span>
                <div>
                    <Link to={sunday} onClick={close} className="sunday-side-drawer">Su</Link>
                    <Link to={monday} onClick={close} className="monday-side-drawer">M</Link>
                    <Link to={tuesday} onClick={close} className="tuesday-side-drawer">Tu</Link>
                    <Link to={wednesday} onClick={close} className="wednesday-side-drawer">W</Link>
                    <Link to={thursday} onClick={close} className="thursday-side-drawer">Th</Link>
                    <Link to={friday} onClick={close} className="friday-side-drawer">F</Link>
                    <Link to={saturday} onClick={close} className="saturday-side-drawer">Sa</Link>
                </div>
            </div>
        );
    } else {
        return (
            <div className="week-container">
                <span className="week-title">Week {number}</span>
                <div>
                    <span className="disabled-week">Su</span>
                    <span className="disabled-week">M</span>
                    <span className="disabled-week">Tu</span>
                    <span className="disabled-week">W</span>
                    <span className="disabled-week">Th</span>
                    <span className="disabled-week">F</span>
                    <span className="disabled-week">Sa</span>
                </div>
            </div>
        );
    }
};

export default Week;