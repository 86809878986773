import React from 'react';
import './DrawerToggle.css';
import { BsFillCalendarFill } from 'react-icons/bs';

const DrawerToggle = ({ onClick, isOpen }) => {
    return (
        <div className="drawer-toggle-container">
            <button className="toggle-btn toggle-calendar-btn btn" onClick={() => onClick(isOpen)}>
                {/* <div className="toggle-btn-line" />
                <div className="toggle-btn-line" />
                <div className="toggle-btn-line" /> */}
                <BsFillCalendarFill />
            </button>
            <button className="toggle-btn btn menu-btn about-link" onClick={() => onClick(isOpen)}>
                Calendar
            </button>
        </div>
    );
};

export default DrawerToggle;