import emailjs from 'emailjs-com';
import GetEmailCredentials from '../firebase/GetEmailCredentials';
import GetEmailLinks from '../firebase/GetEmailLinks'

export default async function SendEmail(name, email) {
    const credentials = await GetEmailCredentials();

    const links = await GetEmailLinks();

    const { pdfLink, epubLink } = links;

    const message = `
        <div>
            <p>Thank you, ${name}, for purchasing the Daily Dinners Ebook!</p>
            <p>PDF File: <a href="${pdfLink}" download target="_blank">Click here to download</a></p>
            <p>EPub File: <a href="${epubLink}" download target="_blank">Click here to download</a></p>
            <p>
                You’ll need to email yourself the pdf for your ereader. You can find your ereader email address by looking under Settings, then Device Options, then Personalization. Once you email it, you will most likely get an email asking you to give yourself permission to email the pdf to your ereader. Follow the instructions that are sent to you in the email asking you to give yourself permission to email the pdf. Once you do that, you might have to email the pdf again. At that point, you will be ready to read the Ebook on your ereader. To find it on your ereader, make sure that you click All Items on your ereader navigation bar. Please feel free to email laura@daily-dinners.com if you have any questions.
            </p>
        </div>`;

    return emailjs.send(
            credentials.filter((c) => (c.type == 'serviceId'))[0].value,
            credentials.filter((c) => (c.type == 'templateId'))[0].value,
            { 
                message, 
                to_email: email
            },
            credentials.filter((c) => (c.type == 'userId'))[0].value,
        ).then(res => {
            return res;
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            return err;
        });  
}