import React, { useEffect } from 'react';
import GetNewsletterEmails from '../firebase/GetNewsletterEmails';
import SendNewsletter from '../helpers/SendNewsletter';

const blacklist = ['smput1963@gmail.com', 'lauraebradham@gmail.com', 'nooni19572001@yahoo.com', 'katiealford@gmail.com', 'JB.bamford@yahoo.com', 'mcbradham58@gmail.com', 'udekathryn@gmail.com',
'davidsenfamily@sbcglobal.net', 'arootlesstree09@gmail.com', 'bjcouts09@gmail.com', 'brookecaudell@gmail.com', 'dozzrn@aol.com', 'Jtaft@harrisoncenter.org', 'bbrady4@gmail.com', 'mcsalyer@hotmail.com', 'usplusour5@gmail.com', 
'davebradham@gmail.com', 'judyfischer@live.ca', 'alisonkopp@gmail.com', 'connigarrison13@gmail.com', 'eastangelia642@gmail.com', 'mjoct2003@yahoo.com'];

const list = ['andrea@in2tech.com.au', 'mcbradham58@gmail.com', 'bobdenice19893@gmail.com',
'jennellfaulkner34@gmail.com', 'lizarawlins0809@yahoo.com', 'mulloolypeter@gmail.com', 
'sreyem661@gmail.com', 'dannakreeves@gmail.com', 'dozzrn@aol.com',
'alisonkopp@gmail.com',
'nooni19572001@yahoo.com',
'Kyleeblehm@gmail.com',
'Ericaalishaskye1993@gmail.com',
'Pamdunn73@gmail.com',
'JB.bamford@yahoo.com',
'eastangelia642@gmail.com',
'bbrady4@gmail.com',
'davidsenfamily@sbcglobal.net',
'brookecaudell@gmail.com',
'smput1963@gmail.com',
'Slong3646@gmail.com',
'jane.b_06@hotmail.com',
'Jtaft@harrisoncenter.org',
'judyfischer@live.ca',
'arootlesstree09@gmail.com',
'davebradham@gmail.com',
'usplusour5@gmail.com',
'connigarrison13@gmail.com',
'bjcouts09@gmail.com',
'johnsonb4540@gmail.com',
'Bridget_milliman@yahoo.com',
'Stikebeverly@aol.com',
'Michele_a_cook@yahoo.com',
'mulloolypeter@gmail.com',
'jkaye@execulink.com',
'bobdenice19893@gmail.com',
'flbradham@gmail.com',
];

const goodList = ['robert.smith.cmt@gmail.com', 'Tplummer@gvr5.net'];

const testList = ['davebradham@gmail.com', 'lauraebradham@gmail.com'];

const badList = ['alisonkopp@gmail.com', 'lizarawlins0809@yahoo.com',
'Bridget_milliman@yahoo.com',
'mcbradham58@gmail.com', 'katiealford@gmail.com',
'johnsonb4540@gmail.com','Stikebeverly@aol.com', 
'jkaye@execulink.com', 'dannakreeves@gmail.com', 
'bobdenice19893@gmail.com', 'udekathryn@gmail.com', 
'sreyem661@gmail.com', 'Michele_a_cook@yahoo.com', 
'Ericaalishaskye1993@gmail.com', 'nooni19572001@yahoo.com', 
'lauraebradham@gmail.com', 'JB.bamford@yahoo.com', 
'jennellfaulkner34@gmail.com', 'andrea@in2tech.com.au', 
'mulloolypeter@gmail.com', 'Stacy@stacymaui.com',
'brookecaudell@gmail.com', 'parkern82589@gmail.com',
'Slong3646@gmail.com', 'smput1963@gmail.com',
'Laura.heinz@ttu.edu', 'flbradham@gmail.com', 
'arootlesstree09@gmail.com', 'mcsalyer@hotmail.com', 
'bbrady4@gmail.com', 'mulloolypeter@gmail.com', 
'Jtaft@harrisoncenter.org', 'jane.b_06@hotmail.com',
'bobdenice19893@gmail.com','davebradham@gmail.com', 
'usplusour5@gmail.com', 'Kimberlysu1995@gmail.com',
'Pamdunn73@gmail.com', 'robert.smith.cmt@gmail.com',
'connigarrison13@gmail.com', 'judyfischer@live.ca', 
'eastangelia642@gmail.com', 'Kyleeblehm@gmail.com', 
'mjoct2003@yahoo.com', 'bjcouts09@gmail.com', 
'davidsenfamily@sbcglobal.net', 'dozzrn@aol.com',
'', 'mulloolypeter@gmail.com',
'', 'Tplummer@gvr5.net',
];

const send = async (email) => {
    if (!list.includes(email)) {
        await SendNewsletter(email);
    }
};
const badStuff = ['alisonkopp@gmail.com', 'Mulloolypeter@gmail.com ', 
'Mulloolypeter@gmail.com', 'mulloolypeter@gmail.com ',
'kbesse@hotmail.com', 'parkern82589@gmail.com',
'Mulloolypeter@gmail.com ', 'mulloolypeter@gmail.com ',
'', 'alisonkopp@gmail.com',
'', '',
'', '',
];

const sentStuff = [
    'jennellfaulkner34@gmail.com', 'mcbradham58@gmail.com',
    'dannakreeves@gmail.com', 'Michele_a_cook@yahoo.com',
    'andrea@in2tech.com.au', 'Kimberlysu1985@gmail.com',
    'hailecs@gmavt.net', 'johnsonb4540@gmail.com',
    'Stikebeverly@aol.com', 'eastangelia642@gmail.com',
    'sreyem661@gmail.com', 'nooni19572001@yahoo.com',
    'davebradham@gmail.com', 'Kyleeblehm@gmail.com',
    'lizarawlins0809@yahoo.com', 'dozzrn@aol.com',
    'lorie.hood@gmail.com', 'judyfischer@live.ca',
    'bobdenice19893@gmail.com', 'Pamdunn73@gmail.com',
    'connigarrison13@gmail.com', 'Stacy@stacymaui.com',
    'jkaye@execulink.com', 'Bridget_milliman@yahoo.com',
    '', 'JB.bamford@yahoo.com',
    '', 'katiealford@gmail.com',
    '', 'Ericaalishaskye1993@gmail.com',
    '', 'udekathryn@gmail.com',
    '', 'lauraebradham@gmail.com',
    'robert.smith.cmt@gmail.com', 'brookecaudell@gmail.com',
    'mjoct2003@yahoo.com', 'Kimberlysu1995@gmail.com',
    'bbrady4@gmail.com', 'Slong3646@gmail.com',
    'robert.smith.cmt@gmail.com', 'Jtaft@harrisoncenter.org',
    'Tplummer@gvr5.net', 'smput1963@gmail.com',
    'jane.b_06@hotmail.com', 'davidsenfamily@sbcglobal.net',
    'usplusour5@gmail.com', 'jane.b_06@hotmail.com ',
    'jessicaebradham@gmail.com', 'arootlesstree09@gmail.com',
    'Laura.heinz@ttu.edu', 'bjcouts09@gmail.com',
    'mcsalyer@hotmail.com', 'flbradham@gmail.com',
];

const Send = () => {

    const getNumberOfSubscribersAsync = async () => {
        const newsletterEmails = await GetNewsletterEmails();
        const emailStrings = [...new Set(newsletterEmails.map(nE => (nE.email)))];

        console.log('s', badList.length);
        emailStrings.map(async email => {
            if (!badStuff.includes(email) && !sentStuff.includes(email)) {
                console.log('sending email to:', email);
                let result = await SendNewsletter(email);
                if (!result) {
                    result = await SendNewsletter(email);
                    /*if (!result) {
                        result = await SendNewsletter(email);
                        if (!result) {
                            result = await SendNewsletter(email);
                            if (!result) {
                                await SendNewsletter(email);
                            }
                        }
                    }*/
                }
            }
        });
    };

    useEffect(() => {
        getNumberOfSubscribersAsync();
    }, []);

    return <div>
        Sent!
    </div>
};

export default Send;