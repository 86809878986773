import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import RecipeBackdrop from './RecipeBackdrop';
import './SpecifcMeal.css';
import './book_recipe.css';
import Read from '../helpers/Read';
import GetDescription from '../helpers/GetDescription';
import TitleElement from './TitleElement';

const SpecificMeal = () => {
    let { mealId } = useParams();
    const [showRecipe, setShowRecipe] = useState(false);
    const mealWordList = mealId.split('~').length > 1 ? mealId.split('~') : mealId.split('-');

    const id = mealWordList.join(' ');
    const dayOfWeek = mealWordList.length > 2 ? mealWordList[2] : mealWordList[1];

    const { recipes, titles } = Read(mealWordList);

    const description = GetDescription(id);

    const showARecipe = (isOpen) => {
        const newIsOpen = !isOpen;
        setShowRecipe(newIsOpen);

        const body = document.querySelector('#root');

        body.scrollIntoView({
            behavior: 'smooth'
        }, 500);
    };
    
    const hideRecipe = () => {
        setShowRecipe(false);
    };
    const [activeRecipeIndex, setActiveRecipeIndex] = useState(0);

    let starBurstColor;
    switch (dayOfWeek) {
        case 'Sunday':
            starBurstColor = 'OrangeBG';
            break;
        case 'Monday':
            starBurstColor = 'Gold';
            break;
        case 'Tuesday':
            starBurstColor = 'Char';
            break;
        case 'Wednesday':
            starBurstColor = 'AvoBG';
            break;
        case 'Thursday':
            starBurstColor = 'WhiteLA';
            break;
        case 'Friday':
            starBurstColor = 'DABG';
            break;
        case 'Saturday':
            starBurstColor = 'Brown';
            break;
    }
    // const starbust = require('../assets/images/starburst' + starBurstColor + '.png');
    const starbust = require('../assets/images/starburst.png');

    const titleElements = titles.map((title, index) => {
        return <div className="title-row">
            <img src={starbust} className="starburst"></img>
            <TitleElement 
                dayOfWeek={dayOfWeek}
                onClick={() => {
                    setActiveRecipeIndex(index);
                    showARecipe(showRecipe);
                }}
                title={title}
            />
            </div>;
    });

    const img = require('../assets/images/' + id + '.png');

    const recipe = showRecipe ? recipes[activeRecipeIndex] : null;
    const closeButton = recipe ? <div 
        className="close-btn" 
        onClick={() => hideRecipe()}>X</div>
    :
    null;

    const className = 'meal-container ' + dayOfWeek;
    return (
        <div className={className}>
            <div className="recipe-content">
                {recipe}
                {closeButton}
            </div>
            <RecipeBackdrop isOpen={showRecipe} onClick={hideRecipe} />
            <h1 className="meal-id">{id}</h1>
            <div className="specific-meal-container">
                <div className="meal-details">
                    <div className="title-elements">{titleElements}</div>
                    <p className="description">{description}</p>
                </div>
                <img src={img} className="meal-pic"></img>
            </div>
        </div>
    );
}

export default SpecificMeal;