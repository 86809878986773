import React, { useState, useEffect } from 'react';
import './Home.css';
import './CreateBlog.css';
import BlogInput from '../components/BlogInput';
import UploadBlog from '../firebase/UploadBlog';
import GetNewsletterEmails from '../firebase/GetNewsletterEmails';

const CreateBlog = () => {
    const [images, setImages] = useState('');
    const [body, setBody] = useState('');
    const [sendDate, setSendDate] = useState('');
    const [subCount, setSubCount] = useState(0);
    const [newsletterEmails, setNewsletterEmails] = useState([]);

    const submit = async () => {
        const blog = { body, sendDate };
        console.log('submitting blog', blog);

        await UploadBlog(blog, images);
    };

    const getNumberOfSubscribersAsync = async () => {
        const newsletterEmails = await GetNewsletterEmails();
        const emailStirngs = [...new Set(newsletterEmails.map(nE => (nE.email)))];
        setSubCount(emailStirngs.length);
        setNewsletterEmails(emailStirngs);
    };

    useEffect(() => {
        getNumberOfSubscribersAsync();
    }, []);

    const handleImageAsFile = (e) => {
        const imageFile = e.target.files[0]
        setImages([...images, imageFile]);
    };

    const newsletterEmailComponents = newsletterEmails.map(newsletterEmail => {
        return <li>{newsletterEmail}</li>;
    });

    return (
        <div className="wrapper blog-creation-container">
            <h1 className="header">
                Daily Dinner Blog Creation
            </h1>

            <BlogInput />

            {/*

            <input
                onChange={handleImageAsFile}
                type="file"
                className="file-input"
            ></input>
            <input
                onChange={handleImageAsFile}
                type="file"
                className="file-input"
            ></input>
            <input
                onChange={handleImageAsFile}
                type="file"
                className="file-input"
            ></input>
            <input
                onChange={handleImageAsFile}
                type="file"
                className="file-input"
            ></input>

            <textarea 
                onChange={e => setBody(e.target.value)}
                className="create-blog-text-area" 
                rows={15}
                placeholder="Body of the blog post..."
            ></textarea>

            <div className="date-input-container">
                <label className="date-label" for="date-input">Send Date:</label>
                <input 
                    id="date-input"
                    name="date-input"
                    onChange={e => setSendDate(e.target.value)} 
                    type="date" 
                    placeholder="Send Date (MM/DD/YYYY)"
                ></input>
            </div>

            <button onClick={() => submit()} className="submit-blog">Submit</button>
            
            */}
            
            <p style={{marginTop: 150}}>
                Number of Subscribers to the newsletter: {subCount}
            </p>
            <ul>
                {newsletterEmailComponents}
            </ul>
        </div>
    );
};

export default CreateBlog;