import React from 'react';

const RecipeBackdrop = ({ isOpen, onClick }) => {
    return isOpen ? <div onClick={() => onClick()} style={styles.container}></div> : null;
};

export default RecipeBackdrop;

const styles = {
    container: {
        background: 'rgba(0, 0, 0, 0.6)',
        zIndex: 3,
        position: 'absolute',
        height: 1045,
        width: '100%',
        marginBottom: 0,
    }
}