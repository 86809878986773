import emailjs from 'emailjs-com';
import GetEmailCredentials from '../firebase/GetEmailCredentials';

export default async function SendEmail(email) {
    const credentials = await GetEmailCredentials();

    const message = `
    <!DOCTYPE html>

    <html>
        <head>
            <style>
                .stripe {
      color: white;
      background: linear-gradient(
        to bottom,
        #623a29,
        #623a29 50%,
        #29cccf 50%,
        #29cccf
      );
      background-size: 100% 45px;
    } 
    .banner-img {
        width: 100%;
    }
    
    @media(max-width: 540px) {
        .banner-img {
            /* width: 80%; */
            /* height: 150px; */
        }
    }
            </style>
        </head>
        <body>
            <table cellpadding="5" align="center" style="
                width: 100%;
                text-align: center;
                max-width: 630px;"
            >
                    <tr>
                        <td>
                            <img
                            class="banner-img"
                            src="https://i.ibb.co/XXJQN9K/digest-Banner.png"
                            alt="Daily Dinners Logo"
                            class="newsletter-logo"
                            style="
                                box-shadow: 0px 0px 3.5px white;
                                margin-bottom: 0px;
                                padding-bottom: 0px;"
                            />
                        </td>
                    </tr>
    
                    <tr cellpadding="0">
                        <td>
                            <h3 style="font-style: italic;margin-top: 0px;padding-top:0px;">
                                Designed to help people that are gluten and/or dairy free, and those who cook for them,
                                to flourish through encouragement and connection!
                            </h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h1 style="margin-bottom: 0px; padding-bottom: 0px;">General Garlic Information</h1>
                        </td>
                    </tr>
                    <tr>
                        <td>                    
                            Garlic is considered a root vegetable, an aromatic, and an herb all at the same time, though
    truthfully, it is a vegetable. It is definitely aromatic and will fill your kitchen with the delightful
    smell of cooking dinner as you use it. We eat the root of the garlic plant, which is called a bulb,
    usually in increments of 1 or 2 cloves. Some recipes use many cloves at once, like <a style="color:black;font-weight: bold;font-style: italic;" href="https://daily-dinners.com/#/purchase">Week 3, on
    Wednesday,</a> Chicken and Vegetables, and, although tasty in that context, it is unusual. While most recipes call for
    your garlic to be minced, there are times you will use smashed or thinly sliced garlic.
                        </td>
                    </tr>
    
                    <tr>
                        <table cellpadding="10" align="center">
                            <tr>
                                <td>
                                    <img
                                    src="https://i.ibb.co/zJhjN23/Garlic1.png"
                                    alt="Daily Dinners Logo"
                                    class="newsletter-logo"
                                    style="width: 225px;
                                        height: 225px;
                                        box-shadow: 0px 0px 3.5px white;
                                        margin-bottom: 0px;
                                        padding-bottom: 0px;"
                                    />
                                </td>
            
                                <td style="text-align: center;">
                                    <img
                                    src="https://i.ibb.co/f8kj9qm/Garlic2.png"
                                    alt="Daily Dinners Logo"
                                    class="newsletter-logo"
                                    style="width: 225px;
                                        height: 225px;
                                        box-shadow: 0px 0px 3.5px white;
                                        margin-bottom: 0px;
                                        padding-bottom: 0px;"
                                    />
                                </td>
                            </tr>
            
                            <tr>
                                <td style="width:275px; vertical-align: top;">
                                    This is the end of the clove of garlic. If you cut off this end of the garlic, you can
                                    easily slip off the tough outer husk of the clove.
                                </td>
                                <td style="width:275px; vertical-align: top;">
                                    This is how the garlic clove looks when the outer husk has been removed. Now you can either
                                    smash it, thinly slice it, or even mince it quite easily.
                                </td>
                            </tr>                        
    
                            <tr>
                                <td style="text-align: center;">
                                    <img
                                    src="https://i.ibb.co/jH6sgDV/Garlic3.png" 
                                    alt="Daily Dinners Logo"
                                    class="newsletter-logo"
                                    style="width: 225px;
                                        height: 225px;
                                        box-shadow: 0px 0px 3.5px white;
                                        margin-bottom: 0px;
                                        padding-bottom: 0px;"
                                    />
                                </td>
            
                                <td style="text-align: center;">
                                    <img
                                        src="https://i.ibb.co/W6hxBs2/Garlic4.png" 
                                        alt="Daily Dinners Logo"
                                        class="newsletter-logo"
                                        style="width: 225px;
                                        height: 225px;
                                                box-shadow: 0px 0px 3.5px white;
                                                margin-bottom: 0px;
                                                padding-bottom: 0px;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style="width:275px; vertical-align: top;">
                                    When a recipe calls for smashed garlic cloves, as in <a style="color:black;font-weight: bold;font-style: italic;" href="https://daily-dinners.com/#/purchase">Week 1, on Monday,</a> Greek-Insprired
                                    Chicken, I have an easy trick to smash the garlic. I use my meat tenderizer. The meat tenderizer has two sides, one
                                    that is flat and one that isn’t. Use the flat side to smash the garlic and you can throw it in the
                                    dish.
                                </td>
                                <td style="width:275px; vertical-align: top;">
                                    If you are thinly slicing the garlic as in <a style="color:black;font-weight: bold;font-style: italic;" href="https://daily-dinners.com/#/purchase">Week 1, on Thursday,</a> Stewed Zucchini, you can do that with a good quality
                                    knife. Or, you can do what I do and use this interesting little kitchen gadget. It is made
                                    specifically for thinly slicing garlic. I got mine at one of those in-home kitchen gadget parties,
                                    but I have found this on Amazon for around $25.00. I’ve never listed this as a device needed to
                                    cook a dish, because you can thinly slice with your knife, but I use this device all of the time.
                                </td>
    
                            </tr>
                        </table>
                    </tr>
    
                    <tr>
                        <table cellpadding="10" align="center">
                            <tr align="center">
                                <td align="center">
                                    <img
                                    src="https://i.ibb.co/c1tSNv0/Garlic5.png" 
                                    alt="Daily Dinners Logo"
                                    class="newsletter-logo"
                                    style="width: 225px;
                                        height: 225px;
                                        box-shadow: 0px 0px 3.5px white;
                                        margin-bottom: 0px;
                                        padding-bottom: 0px;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style="width:450px; vertical-align: top;">
                                    Countless recipes in <a style="color:black;font-weight: bold;font-style: italic;" href="https://daily-dinners.com/#/purchase">Daily Dinners</a> call for
                                    minced garlic. Again, once you have removed the outer husk of the clove of the garlic, you can
                                    do this with a knife. You can buy already minced garlic at the store. Or, you can do what I do,
                                    which I mention in so many recipes, get a Garlic Press (Garlic Mincer). I got this one from one
                                    of those in-home kitchen gadget parties also, but if you don’t know anyone that is selling those
                                    products, you can find it on Amazon for $25.00 as well. I use my Garlic Press to mince garlic
                                    nightly. You do NOT have to remove the husk before using the Garlic Press which is what
                                    makes it such a neat little time saving device.
                                </td>
                            </tr>
                        </table>
                    </tr>
    
                    <tr>
                        <table cellpadding="10" align="center" style="width:100%;max-width: 630px;">
                            <tr>
                                <td>
                                    I find that using fresh herbs makes everyday dinners taste better by providing a freshness and a certain oiliness that only comes from the herbs.  Fresh herbs are considered to be quite good for your health.  If you google the benefits of eating fresh herbs, you will be excited to add them to your life if you haven’t already.  Now that you know there is an Art to Finely Chopping Herbs, you will find them fairly easy to add to your life.  So, now you can get chopping!  Enjoy your day, and enjoy today’s Daily Dinner!
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Always feel free to reply to this email if you have any questions or comments!
                                </td>
                            </tr>
                        </table>
                    </tr>
            </table>
            <table cellpadding="5" align="center" style="
                text-align: center;
                max-width: 630px;
                width:100%;
                background-color: #dddddd;"
            >            
                <tr>
                    <td>
                        <table align="center">
                        <tr align="center" style="width: 300px;">
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                            <a href="http://daily-dinners.com" style="text-decoration: none; font-weight: bold;">
                                                <img src="https://i.ibb.co/MpdNv2b/Logo-with-Plate-04.png" alt="Daily Dinners Logo" />
                                            </a>
                                        </td>
                                        <td>
                                            <a href="http://daily-dinners.com" style="text-decoration: none; font-weight: bold; color: #623a29;">Daily Dinners Home</a>
                                        </td>                                    
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a style="text-decoration: none; font-weight: bold; color: #623a29;" href="http://daily-dinners.com/#/unsubscribe">Unsubscribe</a>
                            </td>
                        </tr>
                    </table>
                    </td>
                </tr>
            </table>
        </body>
    </html>
    `;

    return await emailjs.send(
            credentials.filter((c) => (c.type == 'serviceId'))[0].value,
            'template_brrwf28',
            { 
                message, 
                to_email: email
            },
            credentials.filter((c) => (c.type == 'userId'))[0].value,
        ).then(res => {
            console.log('sucess email:', email);
            return true;
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => {
            console.log('Oh well, you failed. Here some thoughts on the error that occured:', err);
            console.log('failed email!', email);
            return false;
        });  
}