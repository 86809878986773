import firebase from '../firebase';

const IsSubscriber = async (email) => {
      return await firebase.auth().signInAnonymously().then(async (res) => {
        return await firebase.firestore()
        .collection('subscriberEmails')
        .doc(email)
        .get()
        .then(function(doc) {
            if (doc.exists) {
                return true;
            } else {
                return false;
            }
        }).catch(function(error) {
            console.log("Error getting user from firestore:", error);
            return false;
        });
      });
};

export default IsSubscriber;