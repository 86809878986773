import React from 'react';
import './FAQ.css';

const FAQ = () => {
 return (
    <div className="wrapper faq">
        <h1>Frequently Asked Questions</h1>

        <h3>What type of 1-to-1 flour blend do you use?</h3>
        <p>This is a tricky question to answer as I am a very practical person. I do realize that all 1-to-1 type flour blends out there are not equal. And, they aren’t all priced equally, either. When I list a 1-to-1 type flour blend as an ingredient, it means most of the ones out there will work with the recipe. If a recipe needs a good flour blend, I will put the specific flours to be used in the ingredient list. But, my description gives it away: I personally use Bob’s Red Mill Gluten-Free 1-to-1 Baking Flour Blend. It always performs for me when I use it. I have gluten-free friends that swear by other brands, but Bob’s works well for me.</p>

        <h3>What type of oils do you use?</h3>
        <p>As a practical person, I use extra virgin olive oil, canola oil, and toasted sesame oil. There are a host of oils out there. Some are trendy, some are not. These three oils are tried and true and dependable. Am I picky about any of these oils? Well, sort of. I like my oil to come from a single source and feel relatively assured that pesticides were not used. A great daily olive oil comes from California only. And, again, my reference gives it away: I use California Olive Ranch olive oil that’s 100% from California. Read your grocer’s canola oil and make sure that’s from a single source as well. I buy a generic brand for that one. I buy organic toasted sesame oil which is also generic and comes from my local grocer’s aisle.</p>

        <h3>Do I really need to read every package?</h3>
        <p>Yes. As a gluten-free person, you will find many packages stamped with Certified Gluten Free. Many grocery shelves also have a gluten-free emblem that they put on the shelves. But being a dairy free person as well as a gluten free person is why you need to read the label on the back. There is no such thing as a Certified Dairy Free stamp on packages. The best way to tell is by reading the label. At the end of the label there will be a disclaimer saying MAY CONTAIN: (whatever). That may contain blurb will alert you immediately to a milk, nut, soy, or gluten ingredient in a product.</p>

        <h3>What if it’s “Made in a facility that produces products with gluten?”</h3>
        <p>This is something that probably only you can answer. I would answer this way: It must be Certified Gluten Free to make it into my house. Everyone has different sensitivities, so you might be fine with a little contamination. But, we are not fine with that, so be careful and really look at your packages.</p>

        <h3>Is it really all that important to buy organic?</h3>
        <p>Yes. Buying organic will let you see all of the actual ingredients in products. Buying organic fruits and vegetables when you can is also the healthiest choice. I realize that you might have a hard time finding truly organic produce year round. Still, I recommend you try because you are doing this for your health. I understand organics come with a price tag. But, so does your health.</p>

        <h3>I don’t have all the kitchen gadgets that you have.  Can I still make these dinners?</h3>
        <p>Yes, you can still make these dinners. My recommendation is that you work towards collecting these items as you need them. Don’t rush out and buy everything at once. Buy things slowly over time. I use my knife for so many things that a lot of people have a gadget for, so you can also just use your own ingenuity to figure out how to mince garlic rather than buy a good mincer, but the good mincer will be one of your most helpful devices. Still, take a wait and see approach to see what you really need and what you think you can just improvise with from your kitchen.</p>

        <h3>Do you really use a thermometer to measure the temperature of meats?</h3>
        <p>Yes. I have one called ThermoPop that cost me about $20.00. What I love about it is that I can use it inside or outside because it’s small and easy. I measure every piece of chicken, fish, pork, lamb, etc. for doneness. I have complete piece of mind when I serve others that their food is cooked completely. Some ovens come with fancy probe features that you might already have in your kitchen, so be sure to explore your options.</p>

        <h3>What if I have an old family recipe that I don’t know how to convert to gluten-free and dairy free? Can you help me with that?</h3>
        <p>Absolutely! I will try my best! Email me the recipe at lauradailydinners@gmail.com and I will put my best into it. Of course, since it’s your family recipe, I won’t know if it tastes exactly the same once I’ve remade it, so we may be emailing back and forth a bit until it’s perfect.</p>
    </div>
 );
};

export default FAQ;