import firebase from '../firebase';

const PostNewsletterUnsubscribeEmail = async (email) => {
    try {
        return await firebase.auth().signInAnonymously().then(async res => {
            let newsletterUnsubscribers = firebase.firestore().collection('newsletterUnsubscribers');

            await newsletterUnsubscribers.add({ email });
    
            return true;
        });
    } catch (error) {
        console.log('error posting newsletter unsubscriber:', error);
        return false;
    }
};

export default PostNewsletterUnsubscribeEmail;