import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import ThankYou from '../components/ThankYou';

const Thanks = () => {
    let match = useRouteMatch();

    return (
        <Route path={`${match.path}/:type`}>
            <ThankYou />
        </Route>
    );
};

export default Thanks;