import React, { useState } from 'react';
import './App.css';

import Home from './screens/Home';
import About from './screens/About';
import TryItFree from './screens/TryItFree';
import Newsletter from './screens/Newsletter';
import Meal from './screens/Meal';
import Login from './screens/Login';
import Search from './screens/Search';
import Thanks from './screens/Thanks'
import ComplimentaryMeal from './screens/ComplimentaryMeal';
import Purchase from './screens/Purchase';
import CreateBlog from './screens/CreateBlog';
import SignUp from './screens/SignUp';
import FAQ from './screens/FAQ';
import Unsubscribe from './screens/Unsubscribe';
import Send from './screens/Send';

import Toolbar from './components/Toolbar';
import SideDrawer from './components/SideDrawer';
import Backdrop from './components/Backdrop';
import MobileMenu from './components/MobileMenu';

import { Provider as AuthProvider } from './context/AuthContext';

import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import AdminRoute from './routes/AdminRoute';

import {
  HashRouter as Router,
  Switch
} from "react-router-dom";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const openDrawer = (isOpen) => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    setIsBackDropOpen(newIsOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
    setIsBackDropOpen(false);
  };

  const openMobileMenu = (isOpen) => {
    const newIsOpen = !isOpen;
    setIsMobileMenuOpen(newIsOpen);
    setIsBackDropOpen(newIsOpen);
  };

  const closeMobileMenu = (isOpen) => {
    setIsMobileMenuOpen(false);
    setIsBackDropOpen(false);
  };

  return (
      <Router>
        <AuthProvider>
          <div className="App">
            <Toolbar 
              openDrawer={openDrawer} 
              isOpen={isOpen} 
              isMobileMenuOpen={isMobileMenuOpen} 
              openMobileMenu={openMobileMenu}
            />
            <MobileMenu isOpen={isMobileMenuOpen} close={closeMobileMenu} />
            <SideDrawer isOpen={isOpen} close={closeDrawer} />
            <Backdrop isOpen={isBackDropOpen} onClick={() => { closeDrawer(); closeMobileMenu(); }} />
            <Switch>
              <PrivateRoute path="/search" component={Search} />
              <PrivateRoute path="/meal" component={Meal} />

              <PublicRoute path="/faq" component={FAQ} />
              <PublicRoute path="/thanks" component={Thanks} />
              <PublicRoute path="/try-it-free" component={TryItFree} />
              <PublicRoute path="/about" component={About} />
              <PublicRoute path="/complimentary-meal" component={ComplimentaryMeal} />
              <PublicRoute path="/purchase" component={Purchase} />
              <PublicRoute path="/newsletter" component={Newsletter} />
              <PublicRoute path="/login" component={Login} />
              <PublicRoute path="/signup" component={SignUp} />

              <PublicRoute path="/createblog" component={CreateBlog} />

              <PublicRoute path="/unsubscribe" component={Unsubscribe} />

              <PublicRoute path="/send-newsletter" component={Send} />

              <PublicRoute path="/" component={Home} />
            </Switch>
          </div>
        </AuthProvider>
      </Router>
  );
}

export default App;