import React from 'react';
import './About.css';
import CutawayProfile from '../components/CutawayProfile';

const About = () => {
    const targetFoods = require('../assets/images/targetFoods.jpeg');
    const welcome = require('../assets/images/welcome.jpeg');
    const david = require('../assets/images/david.jpg');

    return (
        <div className="about-wrapper">
            
            <div className="about-section">
                <div className="about-inner">
                    <h1 className="header">Daily Dinners: Free of Gluten, Dairy and Anxiety</h1>
                    <h3 className="subheader">
                        Comfort Food Done Differently - A Family Built Application
                    </h3>
                    <p className="welcome-text">
                        Welcome to my table!  And, welcome to a new way of thinking about cooking.
                        I have written this cookbook for one reason only – to make it easier for you to become gluten free and dairy free.
                        It's called Daily Dinners because that's what I've done – figured out dinner.
                    </p>
                </div>
                <img src={welcome} alt="Laura at the dinner table" className="welcome-photo about-photo"></img>
            </div>
            <div className="intro">

                <div className="about-section target-foods-section">
                    <img src={targetFoods} alt="Laura chopping potatoes" className="about-photo target-foods-photo"></img>
                    <div className="about-inner">
                        <h2>Target Foods</h2>
                        <p>
                            I have included something else besides daily food without gluten and dairy in my cookbook – target foods to help your belly if you have had to do this for digestive reasons. 
                            </p>
                            <p>
                            A broad way of stating target foods we are working towards is that they include root vegetables, squashes, avocados, vinegars, seeds, nuts, greens, berries, apples, and herbs. 
                        </p>
                    </div>
                </div>

                <div className="about-section">
                    <div className="about-inner">
                        <h3>Ease of Use</h3>
                        <p>
                            The truth is that I've cooked through these recipes many, many times.  But after I put all of the recipes on paper, the most pleasant surprise to me was how easy it has become to make a grocery list!  All of my meals are planned out, and all I have to do is go through the ingredient lists and make a grocery list before I head out to the store.  Can I mix and match?  Yes, I can, and it's still really easy to make my grocery list.  I want you to have the ability to get started with this lifestyle easily.  You have enough to worry about right now besides “What's for Dinner?” and “How Can I Make this Gluten and Dairy Free?”
                        </p>
                    </div>
                </div>

                <div className="about-section">
                    <div className="about-inner">
                        <h3>Why I Wrote This</h3>
                        <p>
                            You might wonder why I created such a specific cookbook.  A couple of my own family members had to become gluten free and dairy free, the first one over 10 years ago. He went into the gluten free, dairy free lifestyle kicking and screaming.  In some ways, he still is.  A second family member had to make the change for a completely different reason several years later.  In between, several friends had to make the change for other reasons.  Many of these people began asking me questions about being gluten and dairy free because I'd been learning to cook that way.  I decided to write this to help everyone that has had to make the change to gluten free and dairy free, and gone into it kicking and screaming, to make it easier to make the change.  Sure, you feel better if you are gluten free and dairy free.  Sure, you like eating new things that you haven't always eaten, and even liking them.  But that doesn't mean you aren't missing some of the good old food you always knew.  That's where the Comfort Food part of my recipe book comes in.  I've remade old family favorites to our new family standards.  Some of the recipes are named after family and friend's family members, and some are just painstakingly reworked so that the integrity of the dish is there, but the gluten and dairy are not.
                        </p>

                        <p>
                            What I have learned is that many people that have a gut or autoimmune disease are likely to get the suggestion to go gluten free, often dairy free as well.  These are not choices you are just sitting around making one day. And, that's why I think so many people go into this lifestyle kicking and screaming.  It would be a whole different matter if you could have woken up one day and made the decision yourself, but you didn’t.  So, please use this cookbook as a starting point.  I’m here to help if I can.  Feel free to email me at laura@daily-dinners.com.  I know you can do it, and I hope you will feel better daily as you do.
                        </p>
                    </div>
                </div>

                <div className="about-section">
                    <div className="about-inner">
                        <h3>Who Am I?</h3>
                        <p>
                            I am a homecook who has taken comfort food from our old family recipes and brought them up to our new gluten and dairy free standard.  Along with my husband of over 30 years, I’ve raised three children.  I spent many years teaching music, Latin, and math and tutoring subjects that use those subjects as their main language.  Michael and I still live, work, and cook together in our hometown of Houston, Texas.
                        </p>
                    </div>
                </div>

                <div className="about-section">
                    <div className="about-section child-section">
                        <div className="about-inner">
                            <h3>Architect & Engineer of the Application:</h3>
                            <p>
                                David Bradham. He is a software engineer based in Austin, Texas. He is a graduate of University of Texas, Austin. His hobbies include getting outside, exercising, engaging with music, building applications, and watching sports.
                            </p>
                        </div>
                        <img 
                            src={david} 
                            alt="David in an urban corridor with Christmas lights in the background" 
                            className="about-photo david-photo"></img>
                    </div>

                    <div className="about-section child-section">
                        <div className="about-inner">
                            <h3>Head of Formatting & Special Counsel to Artistic Vision:</h3>
                            <p>
                                Jessica Bradham. She is a softtware engineer based in Houston, Texas.  She is a graduate of the University of Houston Honors College.  Her hobbies include birding, writing, reading, and cooking on the weekends.
                            </p>
                        </div>
                    </div>
                </div>


            </div>

            {/* <div className="cutaway">
                <h3 className="subheader-about">
                    We are a Family Built application
                </h3>
                <CutawayProfile 
                    name="Laura Bradham"
                    bio="A home cook who takes comfort food from old family recipes to her 
                        new gluten-free, dairy-free family standard.  Laura and her husband of over 
                        30 years, Michael, raised three children together.  They live, work, 
                        and cook daily in their hometown of Houston, Texas."
                />
                <CutawayProfile 
                    name="David Bradham"
                    bio="A software engineer based in Austin, Texas. He is a graduate of University of Texas, Austin.  
                        His hobbies include getting outside, building applications, and watching sports."
                />
            </div> */}
        </div>
    );
};

export default About;