import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Context } from "../context/AuthContext";

function PrivateRoute({ component: Component, ...rest }) {
  const { state } = useContext(Context);
  const user = state.user;

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default PrivateRoute;