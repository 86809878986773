import firebase from '../firebase';

export default async function() {
    return firebase.auth().signInAnonymously()
    .then(async () => {
        var storage = firebase.storage();
        var storageRef = storage.ref();
    
        const pdfPath = 'ebook/Daily Dinners.pdf';
        const epubPath = 'ebook/DailyDinners.epub';
        
        let pdfPathReference = storageRef.child(pdfPath);
        let epubPathReference = storageRef.child(epubPath);
            
        const pdfLink = await pdfPathReference.getDownloadURL();
        const epubLink = await epubPathReference.getDownloadURL();
    
        return {
            pdfLink,
            epubLink
        };
    })
    .catch((error) => {
        console.log('error getting email links from firebase storage:', error);
        return error;
    });
}