import React from 'react';

const Backdrop = ({ isOpen, onClick }) => {
    return isOpen ? <div onClick={onClick} style={styles.container}></div> : null;
};

export default Backdrop;

const styles = {
    container: {
        background: 'rgba(0, 0, 0, 0.6)',
        zIndex: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }
}