import React from 'react';
import { useParams } from 'react-router-dom';

const ThankYou = () => {
    const params = useParams();
    const type = params.type;

    let words = '';
    switch(type) {
        case 'ebook':
            words = <div className="thank-you-words">
                <h1 className="thank-you-words-header">Thank You for purchasing the Daily Dinners Ebook!</h1>
                <p>
                    Please check for an email that will have a link for you to be able to download the ebook onto your tablet or reader.  
                </p>
                <p>
                    If you have any trouble downloading the ebook or any questions, please email laura@daily-dinners.com. 
                </p>
                <p>
                    We appreciate You!
                </p>
            </div>;
            break;
        case 'book':
            words = <div className="thank-you-words">
                <h1 className="thank-you-words-header">Thank You for purchasing the Daily Dinners Cookbook!</h1>
                <p>
                    You will receive an email when the cookbook ships that will give you a tracking number and estimated delivery date.
                </p>
                <p>
                    We appreciate You!
                </p>
            </div>;
            break;
        case 'subscription':
            words = <div className="thank-you-words">
                <h1 className="thank-you-words-header">
                    Thank You for purchasing the Daily Dinners Subscription Service!
                </h1>
                <p>
                    Please check for an email that will help you set your login.
                </p>
                <p>
                    Once you've logged in, you will find a calendar function on the left hand side of the navigation bar.
                    Click on that button, and a side drawer will emerge to show you the recipes that you have unlocked.
                    Your calendar side drawer will allow you to have access to more recipes each month.
                </p>
                <p>
                    You will also find an Index on the right hand side of the navigation bar so that you can locate the recipes by name if you forget which day they are under.
                    Next to the Index there is a search bar for the same reason.
                </p>
                <p>
                    If you have any questions, or decide that you'd like to cancel your subscription, please contact laura@daily-dinners.com for help.
                </p>
                <p>
                    We appreciate and are here for You!
                </p>
            </div>;
            break;
        case 'combo':
            words = <div className="thank-you-words">
                <h1 className="thank-you-words-header">Thank You for purchasing the Daily Dinners Cookbook and Ebook Combination!</h1>
                <p>
                    Please check for an email that will have a link for you to be able to download the ebook onto your tablet or reader.  If you have any trouble downloading the ebook or any questions, please email laura@daily-dinners.com.
                </p>
                <p>
                    In addition, you will receive an email when the cookbook ships that will give you a tracking number and estimated delivery date.
                </p>
                <p>
                    We appreciate You!
                </p>
            </div>;
            break;
    }

    return (
        <div className="wrapper">
            {words}
        </div>
    );
};

export default ThankYou;