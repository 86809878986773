import React from 'react';
import './Home.css';
import './buttons.css';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Recipes from '../components/Recipes';
import SearchResults from '../components/SearchResults';
import './Search.css';

const Search = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:search`}>
                <SearchResults />
            </Route>
            <Route path={match.path}>
                <Recipes />
            </Route>
        </Switch>
    );
};

export default Search;