import React from 'react';

const TitleElement = ({ title, dayOfWeek = '', onClick }) => {
    const recipeClass = 'recipe-link ' + dayOfWeek;
    return <span
        className={recipeClass} 
        onClick={onClick}
    >{title}</span>;
};

export default TitleElement;