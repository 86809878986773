import React, { useContext, useState, useEffect } from 'react';
import './toolbar.css';
import DrawerToggle from './DrawerToggle';
import { Link, useHistory } from 'react-router-dom';
import { Context } from '../context/AuthContext';
import { FaSearch, FaUser } from 'react-icons/fa';
import { AiOutlineMenuFold } from 'react-icons/ai';

const Toolbar = ({ openDrawer, isOpen, openMobileMenu, isMobileMenuOpen }) => {
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [activeUser, setActiveUser] = useState('');
    const [search, setSearch] = useState('');
    const [showProfileDetail, setShowProfileDetail] = useState(false);

    let history = useHistory();

    const { state, signout } = useContext(Context);
    const user = state.user;

    useEffect(() => {
        if (user && user.subscribed > 0) {
            if (user != activeUser) {
                setIsLoggedOut(false);
                setActiveUser(user);
            }
        }
    });

    const searchPath = "/search/" + search;

    let rightSide = <>
        <li><Link className="about-link" to="/try-it-free">Try It Out For Free!</Link></li>
        <li><Link to="/purchase">Buy the Dinners</Link></li>
    </>;

    let drawerToggle = null;
    let index = null;
    let tryItOutForFree = <Link className="about-link" to="/try-it-free">Try It Out For Free!</Link>;



    if (user && (!isLoggedOut || user != activeUser) && !user.isAnonymous) {
        const userItem = <li>
            <FaUser 
                onClick={() => setShowProfileDetail(!showProfileDetail)} 
                className="user-icon" 
                color="black" 
            />
        </li>;

        if (user.subscribed) {
            drawerToggle = <DrawerToggle onClick={openDrawer} isOpen={isOpen} />;

            rightSide = <>
                <li>
                    <Link className="about-link" to="/meal">Index</Link>
                </li>
                <li className="search-li">
                    <input 
                        placeholder=" Search for Recipes..."
                        className="search-bar"
                        onChange={e => setSearch(e.target.value)}
                        onKeyDown={e => {
                            if (e.key == 'Enter') {
                                setSearch(e.target.value);
                                history.push('/search/' + e.target.value, true);
                            }
                        }}
                    ></input>
                    <Link to={searchPath}>
                        <FaSearch className="search-icon" color="black" />
                    </Link>
                </li>
                {userItem}
            </>;

            tryItOutForFree = null;
        } else {
            rightSide = <>
                <li><Link to="/subscribe">Buy the Dinners</Link></li>
                {userItem}
            </>;
        }
    }

    const profileDetail = showProfileDetail ? 
    <div className="profile-detail-container">
        <div className="profile-detail">
            <span>{user ? user.email : '' }</span>
            <span>
                {user && user.subscribed > 0 ? `Subscription Level: ${user.subscribed}` : `Not currently subscribed`}
            </span>
            <Link to="/purchase" className="sign-out-link">Buy the Dinners</Link>
            <span>
            <Link
                className="sign-out-link"
                onClick={() => {
                    signout();
                    setIsLoggedOut(true);
                    setShowProfileDetail(false);
                }}
                to="/">Sign Out</Link>
            </span>
        </div> 
    </div>
    :
    null;

    const logo = require('../assets/images/Logo with Plate-04.png');

    const leftSideClassName = user && user.subscribed ? 'left-side' : 'left-side left-side-not-subscribed';

    const login = !user || !user.subscribed ? <Link className="about-link" to="/login">Log In</Link> : null;

    return <header className="toolbar">
        <nav className="toolbar-nav">
            <div className={leftSideClassName}>
                {drawerToggle}
                {index}
                {login}
                <Link className="about-link" to="/newsletter">Newsletter</Link>
                <Link className="about-link" to="/about">About</Link>
                <Link className="about-link" to="/faq">FAQ</Link>
            </div>
            <div className="toolbar-logo">
                <Link to="/">
                    <img className="toolbar-logo-icon" src={logo}></img>
                    <span className="logo-text-toolbar">Daily Dinners</span>
                </Link>
            </div>
            <AiOutlineMenuFold className="mobile-menu-btn" onClick={() => openMobileMenu(isMobileMenuOpen)} />
            <div className="toolbar-nav-items">
                <ul>
                    {rightSide}
                </ul>
            </div>
        </nav>
        {profileDetail}
    </header>
};

export default Toolbar;