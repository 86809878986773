import createDataContext from './createDataContext';
import firebase from '../firebase';
import GetUserFromFirestore from '../firebase/GetUserFromFirestore';
import CreateUserInFirestore from '../firebase/CreateUserInFirestore';

const signInWithEmailLink = dispatch => async (email, href) => {
  firebase.auth().signInWithEmailLink(email, href)
  .then(async function(result) {
    // Clear email from storage.
    window.localStorage.removeItem('emailForSignIn');

    const uid = result.user.uid;
    const userInfo = result.additionalUserInfo;
    const isNewUser = userInfo.isNewUser;

    if (isNewUser) {
      const user = { email, uid, subscribed: 1 };
      await CreateUserInFirestore(user);
      dispatch({ type: 'signin', payload: user });
    } else {
      const user = await GetUserFromFirestore(uid);
      dispatch({ type: 'signin', payload: user });
    }
  })
  .catch(function(error) {
    // Some error occurred, you can inspect the code: error.code
    // Common errors could be invalid email and invalid or expired OTPs.
    console.log('error signing in with email link:', error);
  });
};

const logInWithEmail = dispatch => async (email, password) => {
  const res = await firebase.auth().signInWithEmailAndPassword(email, password)
  .then(async (res) => {
    const uid = res.user.uid;
    const user = await GetUserFromFirestore(uid);
    dispatch({ type: 'signin', payload: user });
  })
  .catch(error => {
    return error;
  });
  return res;
};

const signUpWithEmail = dispatch => async (email, password, city, state, age) => {
  const res = await firebase.auth().CreateUserWithEmailAndPassword(email, password).then(async (res) => {
    const uid = res.user.uid;
    const user = { email, uid, subscribed: 1, city, state, age };
    await CreateUserInFirestore(user);
    dispatch({ type: 'signin', payload: user });
  }).catch(error => {
    return error;
  });
  return res;
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'signin':
        return { ...state, user: action.payload };
    case 'signup': 
        return { ...state, user: action.payload };
    case 'signout':
        return { ...state, user: null };
    default:
        return state;
  }
};

const checkAuth = (dispatch) => () => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user != null) {
      const uid = user.uid;
      const firestoreUser = await GetUserFromFirestore(uid).then((user) => {
        return user;
      });
      if (firestoreUser == null) {
        dispatch({ type: 'signup', payload: user });
        return false;
      }
      dispatch({ type: 'signin', payload: firestoreUser });
      return false;
    } else {
      dispatch({ type: 'signup' });
      return false;
    }
  });
}

const signout = dispatch => () => {
    firebase.auth().signOut();
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { checkAuth, signout, logInWithEmail, signUpWithEmail, signInWithEmailLink },
  { user: null }
);