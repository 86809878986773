import firebase from '../firebase';

const AddOrder = async (order) => {
    await firebase.auth().signInAnonymously().then(async (res) => {
        await firebase.firestore()
        .collection('orders')
        .add(order);
    });
};

export default AddOrder;