import React, { useState, useContext } from 'react';
import { Context } from '../context/AuthContext';
import './SignIn.css';
import { useHistory, Link } from 'react-router-dom';
import SendLink from '../firebase/SendLink';
import ValidateEmail from '../helpers/ValidateEmail';
import ResetPassword from '../firebase/ResetPassword';
import IsSubscriber from '../firebase/IsSubscriber';

const Login = () => {
    const { logInWithEmail } = useContext(Context);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showForgotEmail, setShowForgotEmail] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');
    const [showEmailLink, setShowEmailLink] = useState(false);
    const [emailForLink, setEmailForLink] = useState('');

    let history = useHistory();

    const login = async () => {
        const res = await logInWithEmail(email, password);

        if (res && res.message) {
            setError(res.message);
            return;
        }

        history.push('/home');
    };

    const forgotPassword = showForgotEmail ? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <input 
            className="forgot-email-input" 
            placeholder="Enter Your Email..." 
            onChange={e => setForgotEmail(e.target.value)} 
            value={forgotEmail}
            style={{margin: 10}}
        />
        <button className="outside-sign-in-button secondary-btn btn" onClick={async () => {
            const validEmail = ValidateEmail(forgotEmail);
            if (validEmail) {
                const isSubscriber = await IsSubscriber(forgotEmail);
                if (isSubscriber) {
                    ResetPassword(forgotEmail);
                    setError('Thank you, an email will arrive momentarily for you to reset your password');
                    setShowForgotEmail(false);
                } else {
                    setError("Sorry, it doesn't look like you have a subscription with us. If you feel that this is incorrect, please email David@Daily-Dinners.com");
                }
            } else {
                setError('Please provide a valid email address');
            }
        }}>
            Send Password Reset Link
        </button>
    </div>
    :
    null;

    const emailLink = showEmailLink ? <div style={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            marginBottom: 25}}
        >
        <input 
            className="forgot-email-input" 
            placeholder="Enter Your Email..." 
            onChange={e => setEmailForLink(e.target.value)} 
            value={emailForLink}
            style={{margin: 10}}
        />
        <button className="outside-sign-in-button secondary-btn btn" onClick={async () => {
            const isValid = ValidateEmail(emailForLink);
            if (isValid) {
                const isSubscriber = await IsSubscriber(emailForLink);
                if (isSubscriber) {
                    SendLink(emailForLink);
                    setError('Thank you, an email will arrive momentarily for you to log in');
                    setShowEmailLink(false);
                } else {
                    setError("Sorry, it doesn't look like you have a subscription with us. If you feel that this is incorrect, please email David@Daily-Dinners.com");
                }
            } else {
                setError('Please provide a valid email');
            }
        }}>
            Send Link
        </button>
    </div>
    :
    null;

    return (
        <div className="login-screen">
            <h2 className="sign-in-header">Log In!</h2>
            <p className="sign-in-explanation">
                If you have already subscribed to Daily Dinners, you may enter your email and password here to access your recipes.
                Otherwise, <Link className="login-purchase-link" to="/purchase">click here</Link> to subscribe today!
            </p>
            <div className="sign-in-container login-container">
                <div className="sign-in-fields">
                    <input 
                        className="email-input sign-in-input" 
                        placeholder="Email" 
                        onChange={e => setEmail(e.target.value)}
                    ></input>
                    <input 
                        className="password-input sign-in-input" 
                        placeholder="Password" 
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                login();
                            }
                        }}
                        type="password"
                    ></input>
                </div>
                <button 
                    type="button" 
                    className="sign-in-button btn" 
                    onClick={() => login()}
                >Log In</button>
            </div>
            <div className="error-zone">
                {error}
            </div>
            <button 
                className="outside-sign-in-button btn"
                onClick={() => { 
                    setShowEmailLink(!showEmailLink); 
                    setShowForgotEmail(false); 
                }}
            >
                Send A One Time Email Link to Sign In
            </button>
            {emailLink}
            <button 
                className="outside-sign-in-button btn" 
                onClick={() => {
                    setShowForgotEmail(!showForgotEmail);
                    setShowEmailLink(false);
                }}
                style={{ marginTop: 15 }}
            >
                Forgot password?
            </button>
            {forgotPassword}
        </div>
    );
};

export default Login;