import firebase from '../firebase';

const AddSubscriberEmail = async (email) => {
    await firebase.auth().signInAnonymously().then(async (res) => {
        await firebase.firestore()
        .collection('subscriberEmails')
        .doc(email.email)
        .set(email);
    });
};

export default AddSubscriberEmail;