import React from 'react';
import './Home.css';
import './buttons.css';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SpecificMeal from '../components/SpecificMeal';

const ComplimentaryMeal = () => {
    let match = useRouteMatch();

    return (
        <Route path={`${match.path}/:mealId`}>
        <SpecificMeal />
    </Route>
    );
};

export default ComplimentaryMeal;