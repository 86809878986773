import emailjs from 'emailjs-com';
import GetEmailCredentials from '../firebase/GetEmailCredentials';

export default async function SendEmail(name, address) {
    const credentials = await GetEmailCredentials();

    const addressString = Object.keys(address).map(key => {
        return address[key] + ' ';
    });

    const message = `
        <div>
            <p>${name} has just purchased the Daily Dinners cookbook and will need a copy shipped ASAP.</p>
            <p>Their address: ${addressString}</p>
        </div>`;

    return emailjs.send(
            credentials.filter((c) => (c.type == 'serviceId'))[0].value,
            credentials.filter((c) => (c.type == 'templateId'))[0].value,
            { 
                message, 
                to_email: 'LauraDailyDinners@gmail.com'
            },
            credentials.filter((c) => (c.type == 'userId'))[0].value,
        ).then(res => {
            return res;
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            return err;
        });  
}