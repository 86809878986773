import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import AddOrder from "../firebase/AddOrder";
import AddPayer from "../firebase/AddPayer";
import AddSubscriberEmail from '../firebase/AddSubscriberEmail';
import ResetPassword from '../firebase/ResetPassword';
import SendLink from '../firebase/SendLink';
import { useHistory } from 'react-router-dom';

const SubscribeButton = () => {
    let history = useHistory();

    return (
        <div id="payment-form-wrapper">
            <div id="payment-form-container">
                <PayPalButton
                    // options={{vault: true}}
                    shippingPreference="NO_SHIPPING"
                    options={{
                        vault: true,
                        // clientId: "AeWJEYqd2g_ngFVRl2NWHaJBDB-TWP4uRdzMrrkPRz70uArSxpHyzIh-OT_12Niuj6ZI9Yqy1A-OtNuR" // testing
                        clientId: "AdAr45Mo_wgg0pAplTQbNx69vsynubiXt1b_EArobjcBpe2GIecrMZyyDuIhZIG5QlUwITsb4XisT1zf" // production
                      }}
                    createSubscription={(data, actions) => {
                        return actions.subscription.create({
                            plan_id: 'P-65G798545W195102AL62FYXY'
                        });
                    }}
                    onApprove={(data, actions) => {
                    // Capture the funds from the transaction
                    return actions.subscription.get().then(function(details) {
                        const subscriber = details.subscriber;

                        const email = subscriber.email_address;
                        // const phone = subscriber.phone;
                        const name = subscriber.name;

                        const order = { email, /*phone,*/ name, cost: '4.99', item: 'Subscription', date: new Date() };

                        AddSubscriberEmail({ email });
                        SendLink(email);
                        ResetPassword(email);
                        AddOrder(order);
                        AddPayer(email);

                        history.push('/thanks/subscription');
            
                        // OPTIONAL: Call your server to save the subscription
                        // return fetch("/paypal-subscription-complete", {
                        //   method: "post",
                        //   body: JSON.stringify({
                        //     orderID: data.orderID,
                        //     subscriptionID: data.subscriptionID
                        //   })
                        // });
                    });
                    }}
                />
            </div>
        </div>
    );
}

export default SubscribeButton;