import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import './MobileMenu.css';
import { Context } from '../context/AuthContext';
import { FaSearch, FaUser } from 'react-icons/fa';

const MobileMenu = ({ isOpen, close }) => {
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [search, setSearch] = useState('');

    const { state, signout } = useContext(Context);
    const user = state.user;

    let history = useHistory();

    if (!isOpen) {
        return null;
    }

    if (user && user.subscribed) {
        const searchPath = "/search/" + search;

        return (
            <div className="mobile-menu-container">
                <div className="search-div">
                    <input 
                        placeholder=" Search for Recipes..."
                        className="search-bar"
                        onChange={e => setSearch(e.target.value)}
                        onKeyDown={e => {
                            if (e.key == 'Enter') {
                                setSearch(e.target.value);
                                history.push('/search/' + e.target.value, true);
                                close();
                            }
                        }}
                    ></input>
                    <Link to={searchPath} onClick={() => close()}>
                        <FaSearch className="search-icon" color="black" />
                    </Link>
                </div>

                <Link 
                    onClick={() => close()} 
                    className="mobile-menu-link about-link" 
                    to="/meal"
                >Index</Link>

                <Link 
                    onClick={() => close()} 
                    className="mobile-menu-link about-link"
                    to="/newsletter"
                >Newsletter</Link>

                <Link 
                    onClick={() => close()}
                    className="mobile-menu-link about-link" 
                    to="/faq"
                >FAQ</Link>

                <Link 
                    onClick={() => close()} 
                    className="mobile-menu-link about-link"
                    to="/about"
                >About</Link>
                    
                <Link 
                    onClick={() => close()} 
                    className="mobile-menu-link about-link"
                    to="/purchase"
                >Buy the Dinners</Link>

                <div className="mobile-menu-account-detail">
                    <span>{user ? user.email : '' }</span>
                    <span>
                        {user && user.subscribed > 0 ? `Subscription level: ${user.subscribed}` : `Not currently subscribed`}
                    </span>
                    <span>
                    <Link
                        className="sign-out-link"
                        onClick={() => {
                            signout();
                            setIsLoggedOut(true);
                        }}
                        to="/">Sign Out</Link>
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className="mobile-menu-container">
            <Link 
                onClick={() => close()}
                className="mobile-menu-link about-link" 
                to="/try-it-free"
            >Try It Out For Free!</Link>

            <Link 
                onClick={() => close()}
                className="mobile-menu-link about-link" 
                to="/faq"
            >FAQ</Link>

            <Link 
                onClick={() => close()} 
                className="mobile-menu-link about-link"
                to="/purchase"
            >Buy the Dinners</Link>

            <Link 
                onClick={() => close()} 
                className="mobile-menu-link about-link"
                to="/about"
            >About</Link>

            <Link 
                onClick={() => close()} 
                className="mobile-menu-link about-link"
                to="/newsletter"
            >Newsletter</Link>

            <Link 
                onClick={() => close()} 
                className="mobile-menu-link about-link" 
                to="/login"
            >Log In</Link>
        </div>
    );
};

export default MobileMenu;