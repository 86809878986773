import firebase from '../firebase';

var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://daily-dinners.com/#/signup',

    // url :'http://localhost:3000/#/signup',
    handleCodeInApp: true,

    // iOS: {
    //   bundleId: 'com.example.ios'
    // },

    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },

    // dynamicLinkDomain: 'example.page.link'
};

const SendLink = (email) => {
    firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
    .then(function() {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
    })
    .catch(function(error) {
        console.log('error:', error);
      // Some error occurred, you can inspect the code: error.code
    });
};

export default SendLink;