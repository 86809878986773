import firebase from '../firebase';

const UploadBlog = async (blog, images) => {
    firebase.auth().signInAnonymously().then(() => {
        let blogs = firebase.firestore()
        .collection('blogs');

        blogs.add(blog);/*.then((docRef) => {
        images.forEach((image, i) => {
            firebase.storage()
                .ref(`/blogImages/${docRef.id}-${i}`)
                .put(image);
        });
        })
        .catch(error => {
        console.error('error!', error);
        });*/
    });
};

export default UploadBlog;