import React from 'react';
import './Home.css';
import './buttons.css';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SpecificMeal from '../components/SpecificMeal';
import Recipes from '../components/Recipes';

const Meal = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:mealId`}>
                <SpecificMeal />
            </Route>
            <Route path={match.path}>
                <Recipes />
            </Route>
        </Switch>
    );
};

export default Meal;